import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import "../assets/css/searchBarButtons.css";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
    color: state.isFocused ? "#fff" : "#000",
    "&:hover": {
      backgroundColor: "#ff9b44",
    },
  }),
};

const InputField = ({ label, children }) => (
  <div className="col-sm-3">
    <div className="input-block form-focus select-focus">
      {children}
      <label className="focus-label">{label}</label>
    </div>
  </div>
);

const AttendanceEmployeeFilter = ({ searchEmployees }) => {
  const [employeeName, setEmployeeName] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  // Handle employee name change
  const handleNameChange = (e) => setEmployeeName(e.target.value);

  // Handle month and year selection
  const handleMonthChange = (date, dateString) => {
    setSelectedMonth(dateString);
  };

  const handleYearChange = (date, dateString) => {
    setSelectedYear(dateString);
  };

  // Submit the form
  const handleSubmit = () => {
    searchEmployees({
      month: selectedMonth || null,
      year: selectedYear || null,
      employee_name: employeeName || "",
    });
  };

  // Reset the filters
  const handleReset = () => {
    setEmployeeName("");
    setSelectedMonth(null);
    setSelectedYear(null);
    searchEmployees({
      month: null,
      year: null,
      employee_name: "",
    });
  };

  return (
    <div className="row filter-row">
      <InputField label="Employee Name">
        <input
          type="text"
          className="form-control floating"
          value={employeeName}
          onChange={handleNameChange}
          onFocus={() => {}}
        />
      </InputField>

      <InputField label="Select Month & Year">
        <DatePicker
          picker="month"
          format="MMMM YYYY"
          className="form-control floating datetimepicker"
          onChange={handleMonthChange}
          value={selectedMonth ? dayjs(selectedMonth, "MMMM YYYY") : null}
          placeholder="Select Month & Year"
        />
      </InputField>

      <div className="col-sm-2 mb-2 containerStyle">
        <Link to="#" onClick={handleSubmit} className="me-2 linkStyle">
          <BsSearch className="iconStyle" />
        </Link>
        <Link to="#" onClick={handleReset} className="linkStyle">
          <MdOutlineSettingsBackupRestore className="iconStyle" />
        </Link>
      </div>
    </div>
  );
};

export default AttendanceEmployeeFilter;
