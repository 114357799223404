import React, { useEffect } from 'react';
import Swal from 'sweetalert2';

const InfoToast = ({ success, handleClose, toastType }) => {
    useEffect(() => {
        if (success) {
            Swal.fire({
                title: toastType.type === "Error" ? "Error" : "Success",
                text: toastType.label,
                icon: toastType.type === "Error" ? "error" : toastType.type === "Added" ? "success" : "success",
                confirmButtonText: 'OK',
                background:  "#fff",
                timer: 4000,
                timerProgressBar: true,
                position: 'center',
                showConfirmButton: false,
                backdrop: 'rgba(0,0,0,0.5)', 
                didClose: handleClose,
            });
        }
    }, [success, toastType]);

    return null;
};

export default InfoToast;
