import React, { useEffect, useState } from "react";
// import DatePicker from "react-datepicker";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import moment from "moment";

const AllEmployeeAddPopup = (props) => {
  const [department, setDepartment] = useState([]);
  const [designation, setDesignation] = useState([]);
  const [shift, setShift] = useState([]);
  const [filteredDesignations, setFilteredDesignations] = useState([]);
  const companies = [{ value: 1, label: "Xpert Prime" }];
  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  useEffect(() => {
    if (props.department) {
      setDepartment(
        props.department.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    }
  }, [props.department]);
  
  useEffect(() => {
    if (props?.shiftData) {
      setShift(
        props.shiftData?.shifts.map((item) => ({
          value: item.id,
          label: item.shift_name,
        }))
      );
    }
  }, [props.shiftData]);

  useEffect(() => {
    if (props.department) {
      setDesignation(
        props.designation.map((item) => ({
          value: item.id,
          label: item.name,
          department_id: item.department_id,
        }))
      );
    }
  }, [props.designation]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    gender: yup.object().required("Gender is required"),
    address: yup.string().required("Address is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    company_email: yup
      .string()
      .email("Invalid email")
      .required("Company Email is required"),
    employeeId: yup.string().required("Employee ID is required"),
    joiningDate: yup.date().required("Joining Date is required"),
    birthday: yup.date().required("Birthday date is required"),
    department: yup.object().required("Department is required"),
    designation: yup.object().required("Designation is required"),
    shift: yup.object().required("Shift is required"),
    company: yup.object().required("Company is required"),
    password: props.edit
      ? yup.string()
      : yup
          .string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters")
          .matches(
            /^(?=.*[A-Z])/,
            "Password must contain at least one uppercase letter"
          )
          .matches(
            /^(?=.*[a-z])/,
            "Password must contain at least one lowercase letter"
          )
          .matches(
            /^(?=.*[0-9])/,
            "Password must contain at least one numeric digit"
          )
          .matches(
            /^(?=.*[!@#$%^&*()_+{}\[\]:<>,.?/~\|])/,
            "Password must contain at least one special character"
          ),
    confirmPassword: props.edit
      ? yup.string().oneOf([yup.ref("password"), null], "Passwords must match")
      : yup
          .string()
          .required("Confirm Password is required")
          .oneOf([yup.ref("password"), null], "Passwords must match"),
    phone: yup
      .string()
      .required("Phone number is required")
      .max(14, "Phone number must be less than or equal to 14 digits"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      // username: "",
      address: "",
      gender: "",
      email: "",
      password: "",
      confirmPassword: "",
      employeeId: "",
      birthday: null,
      joiningDate: null,
      phone: "",
      company: null,
      company_email: null,
      department: null,
      designation: null,
      shift_id: null,
    },
  });

  useEffect(() => {
    if (props.employee) {
      reset();
      setValue("firstName", props.employee.firstname || "");
      setValue("lastName", props.employee.lastname || "");
      // setValue("username", props.employee.username || "");
      setValue("address", props.employee.address || "");
      setValue(
        "gender",
        genderOptions.find(
          (option) => option.value === props.employee.gender
        ) || ""
      );
      setValue("email", props.employee.email || "");
      setValue("employeeId", props.employee.uuid || "");
      setValue("birthday", props.employee.birthday || null);
      setValue("joiningDate", props.employee.joining_date || null);
      setValue(
        "department",
        props.employee.department
          ? {
              value: props.employee.department.id,
              label: props.employee.department.name,
            }
          : null
      );
      setValue(
        "designation",
        props.employee.designation
          ? {
              value: props.employee.designation.id,
              label: props.employee.designation.name,
            }
          : null
      );
      setValue("company", {
        value: 1,
        label: props.employee.company || "Xpert Prime",
      });
      setValue("phone", props.employee.phone || "");
      setValue("company_email", props.employee.company_email || "");
    }
  }, [props.employee, reset, setValue]);

  const selectedDepartment = watch("department");
  useEffect(() => {
    if (selectedDepartment) {
      const filtered = designation.filter(
        (item) => item.department_id === selectedDepartment.value
      );
      setFilteredDesignations(filtered);
      setValue("designation", null);
    }
  },[selectedDepartment]);

  const onSubmit = (data) => {
    const employeeID = parseInt(data.employeeId);
    const formattedJoiningDate = dayjs(data.joiningDate).format("YYYY-MM-DD");
    const formattedBirthday = dayjs(data.birthday).format("YYYY-MM-DD");
    const payload = {
      uuid: employeeID,
      firstname: data.firstName,
      lastname: data.lastName,
      // username: data.username,
      email: data.email,
      phone: data.phone,
      address: data.address,
      gender: data.gender.value,
      company: data.company.label,
      company_email: data.company_email,
      department: data.department.value,
      designation: data.designation.value,
      shift_id: data.shift.value,
      password: data.password,
      password_confirmation: data.confirmPassword,
      birthday: formattedBirthday,
      joining_date: formattedJoiningDate,
    };

    props.addEmployeeData(payload);
  };

  const onSubmitUpdate = (data) => {
    const employeeID = parseInt(data.employeeId);
    const formattedJoiningDate = dayjs(data.joiningDate).format("YYYY-MM-DD");
    const formattedBirthday = dayjs(data.birthday).format("YYYY-MM-DD");
    const payload = {
      uuid: employeeID,
      firstname: data.firstName,
      lastname: data.lastName,
      // username: data.username,
      email: data.email,
      phone: data.phone,
      address: data.address,
      gender: data.gender.value,
      company: data.company.label,
      company_email: data.company_email,
      department: data.department.value,
      designation: data.designation.value,
      shift: data.shift.value,
      password: data.password,
      password_confirmation: data.confirmPassword,
      birthday: formattedBirthday,
      joining_date: formattedJoiningDate,
      id: props.employee.id,
    };

    props.updateEmployeeData(payload);
  };

  return (
    <>
      <div id="add_employee" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Employee</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="profile-img-wrap edit-img">
                  <img className="inline-block" src="" alt="user" />
                  <div className="fileupload btn">
                    <span className="btn-text">edit</span>
                    <input className="upload" type="file" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              }`}
                              type="text"
                            />
                            {errors.firstName && (
                              <p className="text-danger">
                                {errors.firstName.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className="form-control"
                              type="text"
                            />
                            {errors.lastName && (
                              <p className="text-danger">
                                {errors.lastName.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Employee ID <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="employeeId"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.employeeId ? "is-invalid" : ""
                              }`}
                              type="text"
                            />
                            {errors.employeeId && (
                              <p className="text-danger">
                                {errors.employeeId.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Company Email <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="company_email"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                          />
                        )}
                      />
                      {errors.company_email && (
                        <p className="text-danger">
                          {errors.company_email.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Password <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.password ? "is-invalid" : ""
                              }`}
                              type="password"
                            />
                            {errors.password && (
                              <p className="text-danger">
                                {errors.password.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Confirm Password <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.confirmPassword ? "is-invalid" : ""
                              }`}
                              type="password"
                            />
                            {errors.confirmPassword && (
                              <p className="text-danger">
                                {errors.confirmPassword.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Personal Email <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              type="email"
                            />
                            {errors.email && (
                              <p className="text-danger">
                                {errors.email.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  {/* <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Username <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="username"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.username ? "is-invalid" : ""
                              }`}
                              type="text"
                            />
                            {errors.username && (
                              <p className="text-danger">
                                {errors.username.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div> */}
                  <div className="col-sm-6">
                    <div
                      className="input-block mb-3"
                      style={{ position: `relative` }}
                    >
                      <label className="col-form-label">
                        Joining Date <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="joiningDate"
                        control={control}
                        render={({ field }) => (
                          <div className="relative">
                            <DatePicker
                              value={field.value ? dayjs(field.value) : null}
                              disabledDate={disabledDate}
                              format="DD-MM-YYYY"
                              onChange={(date) =>
                                field.onChange(date ? date.toISOString() : null)
                              }
                              className={`form-control cal-icon ${
                                errors.joiningDate ? "is-invalid" : ""
                              }`}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                            />
                            {errors.joiningDate && (
                              <p className="text-danger">
                                {errors.joiningDate.message}
                              </p>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Phone <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                          />
                        )}
                      />
                      {errors.phone && (
                        <p className="text-danger">{errors.phone.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="input-block mb-3"
                      style={{ position: `relative` }}
                    >
                      <label className="col-form-label">
                        Birthday <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="birthday"
                        control={control}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              selected={field.value}
                              disabledDate={disabledDate}
                              format="DD-MM-YYYY"
                              value={field.value ? dayjs(field.value) : null}
                              onChange={(date) => field.onChange(date)}
                              className={`form-control cal-icon ${
                                errors.birthday ? "is-invalid" : ""
                              }`}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                            />
                            {errors.birthday && (
                              <p className="text-danger">
                                {errors.birthday.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Gender <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={genderOptions}
                            placeholder="Select Gender"
                            styles={customStyles}
                            onChange={(option) => field.onChange(option)}
                          />
                        )}
                      />
                      {errors.gender && (
                        <p className="text-danger">{errors.gender.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Address <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                          />
                        )}
                      />
                      {errors.address && (
                        <p className="text-danger">{errors.address.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Company <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="company"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={companies}
                            placeholder="Select"
                            styles={customStyles}
                            onChange={(option) => field.onChange(option)}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Department <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="department"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              options={department}
                              placeholder="Select"
                              styles={customStyles}
                              onChange={(option) => field.onChange(option)}
                            />
                            {errors.department && (
                              <p className="text-danger">
                                {errors.department.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Designation <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="designation"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              options={filteredDesignations}
                              placeholder="Select"
                              styles={customStyles}
                              onChange={(option) => field.onChange(option)}
                            />
                            {errors.designation && (
                              <p className="text-danger">
                                {errors.designation.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Shift <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="shift"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              options={shift}
                              placeholder="Select"
                              styles={customStyles}
                              onChange={(option) => field.onChange(option)}
                            />
                            {errors.shift && (
                              <p className="text-danger">
                                {errors.shift.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    type="submit"
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    disabled={!isValid}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ==============================================Edit Employee======================================== */}
      <div id="edit_employee" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Employee</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmitUpdate)}>
                <div className="profile-img-wrap edit-img">
                  <img className="inline-block" src="" alt="user" />
                  <div className="fileupload btn">
                    <span className="btn-text">edit</span>
                    <input className="upload" type="file" />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              }`}
                              type="text"
                            />
                            {errors.firstName && (
                              <p className="text-danger">
                                {errors.firstName.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className="form-control"
                              type="text"
                            />
                            {errors.lastName && (
                              <p className="text-danger">
                                {errors.lastName.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Employee ID <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="employeeId"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.employeeId ? "is-invalid" : ""
                              }`}
                              type="text"
                            />
                            {errors.employeeId && (
                              <p className="text-danger">
                                {errors.employeeId.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Company Email <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="company_email"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                          />
                        )}
                      />
                      {errors.company_email && (
                        <p className="text-danger">
                          {errors.company_email.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Password</label>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.password ? "is-invalid" : ""
                              }`}
                              type="password"
                            />
                            {errors.password && (
                              <p className="text-danger">
                                {errors.password.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Confirm Password</label>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.confirmPassword ? "is-invalid" : ""
                              }`}
                              type="password"
                            />
                            {errors.confirmPassword && (
                              <p className="text-danger">
                                {errors.confirmPassword.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Personal Email <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              type="email"
                            />
                            {errors.email && (
                              <p className="text-danger">
                                {errors.email.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="input-block mb-3"
                      style={{ position: `relative` }}
                    >
                      <label className="col-form-label">
                        Joining Date <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="joiningDate"
                        control={control}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              selected={field.value}
                              value={field.value ? dayjs(field.value) : null}
                              format="DD-MM-YYYY"
                              onChange={(date) => field.onChange(date)}
                              className={`form-control cal-icon ${
                                errors.joiningDate ? "is-invalid" : ""
                              }`}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                            />
                            {errors.joiningDate && (
                              <p className="text-danger">
                                {errors.joiningDate.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Phone <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                          />
                        )}
                      />
                      {errors.phone && (
                        <p className="text-danger">{errors.phone.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="input-block mb-3"
                      style={{ position: `relative` }}
                    >
                      <label className="col-form-label">
                        Birthday <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="birthday"
                        control={control}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              selected={field.value}
                              value={field.value ? dayjs(field.value) : null}
                              format="DD-MM-YYYY"
                              onChange={(date) => field.onChange(date)}
                              className={`form-control cal-icon ${
                                errors.birthday ? "is-invalid" : ""
                              }`}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                            />
                            {errors.birthday && (
                              <p className="text-danger">
                                {errors.birthday.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Gender <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={genderOptions}
                            placeholder="Select Gender"
                            styles={customStyles}
                            onChange={(option) => field.onChange(option)}
                            value={field.value}
                          />
                        )}
                      />
                      {errors.gender && (
                        <p className="text-danger">{errors.gender.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Address <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                          />
                        )}
                      />
                      {errors.address && (
                        <p className="text-danger">{errors.address.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Company <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="company"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={companies}
                            placeholder="Select"
                            styles={customStyles}
                            onChange={(option) => field.onChange(option)}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Department <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="department"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              options={department}
                              placeholder="Select"
                              styles={customStyles}
                              onChange={(option) => field.onChange(option)}
                            />
                            {errors.department && (
                              <p className="text-danger">
                                {errors.department.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Designation <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="designation"
                        control={control}
                        render={({ field }) => (
                          <>
                           <Select
                              {...field}
                              options={filteredDesignations}
                              placeholder="Select"
                              styles={customStyles}
                              onChange={(option) => field.onChange(option)}
                            />
                            {errors.designation && (
                              <p className="text-danger">
                                {errors.designation.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    type="submit"
                    className="btn btn-primary submit-btn"
                    disabled={!isValid}
                    data-bs-dismiss="modal"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllEmployeeAddPopup;
