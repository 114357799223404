import React, { useEffect } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import UserFilter from "./UserFilter";
import UsersTable from "./UsersTable";
import AddUserModal from "../../../../components/Administration/Users/AddUseModal";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";
import InfoToast from "../../../layout/InfoToast";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createUser,
  fetchUsers,
  deleteUser,
  updateUser,
} from "../../../../hooks/useUser";
import { useState } from "react";

const Users = () => {
  const [success, setSuccess] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [toastType, setToastType] = useState("");
  const [selectUserId, setselectUserId] = useState(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  // ========================================GEt USer Data===========================
  const { data, error, status, refetch } = useQuery({
    queryKey: ["users"],
    queryFn: fetchUsers,
    refetchOnWindowFocus: false,
  });
  // ========================================Add User===========================
  const { mutate: createUserMutation } = useMutation({
    mutationFn: createUser,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "User Added", type: "Added" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Adding User",
        type: "Error",
      });
    },
  });

  const createNewUser = (data) => {
    createUserMutation(data);
  };

  // ========================================Delete User===========================

  const { mutate: DeleteUserMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: deleteUser,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));

      setToastType({ label: "User Deleted", type: "Deleted" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));

      setToastType({
        label: "An Error Occurred While Deleting User",
        type: "Error",
      });
    },
  });

  const deleteUserId = (id) => {
    DeleteUserMutation(id);
  };
  const handleSelectUserForDeletion = (id) => {
    setselectUserId(id);
  };

  // ========================================Edit User=======================================

  const { mutate: EditUserMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateUser,
    onSuccess: () => {
      refetch.then(() => setIsRefetching(false)).then(() => setSuccess(true));
      setToastType({ label: "User Updated", type: "Updated" });
    },
    onError: () => {
      refetch.then(() => setIsRefetching(false)).then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Updating User",
        type: "Error",
      });
    },
  });

  const updateUserdata = (data) => {
    EditUserMutation(data);
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Users "
          title="Dashboard"
          subtitle="Users"
          modal="#add_user"
          name="Add User"
        />
        <UserFilter />
        <UsersTable
          data={data}
          onSelectUserForDeletion={handleSelectUserForDeletion}
          updateUser={updateUserdata}
        />
        <AddUserModal createNewUser={createNewUser} />

        <DeleteModal
          Name="Delete User"
          deleteDepartment={() => deleteUserId(selectUserId)} //// hint deleteDepartment Conflict in DeleteModal
        />
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      </div>
    </div>
  );
};

export default Users;
