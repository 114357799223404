import React, { useState, useEffect } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

export const AddHoliday = ({
  addHolidayItem,
  updateHolidayItem,
  selectedHolidayName,
  selectedHolidayDate,
}) => {
  const [holidayName, setHolidayName] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [updateHolidayName, setUpdateHolidayName] = useState("");
  const [updateHolidayDate, setUpdateHolidayDate] = useState(null);
  const isAddSubmitDisabled = !holidayName || !selectedDate;
  const isEditSubmitDisabled = !updateHolidayName || !updateHolidayDate;

  // Update the state when selectedHolidayName or selectedHolidayDate changes
  useEffect(() => {
    setUpdateHolidayName(selectedHolidayName);

    // Check if selectedHolidayDate is a valid date string
    if (selectedHolidayDate) {
      const date = dayjs(selectedHolidayDate, "DD-MM-YYYY"); // Use your date format here
      setUpdateHolidayDate(date.isValid() ? date : null);
    } else {
      setUpdateHolidayDate(null);
    }
  }, [selectedHolidayName, selectedHolidayDate]);

  // Handle modal show and hide events
  useEffect(() => {
    const modal = document.getElementById("add_holiday");
    const handleShow = () => {
      setHolidayName("");
      setSelectedDate(null);
    };

    modal.addEventListener("shown.bs.modal", handleShow);

    return () => {
      modal.removeEventListener("shown.bs.modal", handleShow);
    };
  }, []);

  // Handle input changes
  const handleNameChange = (e) => setHolidayName(e.target.value);
  const handleDateChange = (date) => setSelectedDate(date);
  const handleUpdateNameChange = (e) => setUpdateHolidayName(e.target.value);
  const handleUpdateDateChange = (date) => setUpdateHolidayDate(date);

  // Handle form submissions
  const handleSubmit = () => {
    if (holidayName && selectedDate) {
      const holiday = {
        name: holidayName,
        holiday_date: dayjs(selectedDate).format("YYYY-MM-DD"),
      };
      addHolidayItem(holiday);
      setHolidayName("");
      setSelectedDate(null);
    } else {
      alert("Please fill all the fields");
    }
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    if (updateHolidayName && updateHolidayDate) {
      const holiday = {
        name: updateHolidayName,
        holiday_date: dayjs(updateHolidayDate).format("YYYY-MM-DD"),
      };
      updateHolidayItem(holiday);
    } else {
      alert("Please fill all the fields");
    }
  };

  return (
    <div>
      {/* Add Holiday Modal */}
      <div className="modal custom-modal fade" id="add_holiday" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Holiday</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Holiday Name <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  type="text"
                  value={holidayName}
                  onChange={handleNameChange}
                />
              </div>
              <div
                className="input-block mb-3"
                style={{ position: "relative" }}
              >
                <label className="col-form-label">
                  Holiday Date <span className="text-danger">*</span>
                </label>
                <DatePicker
                  value={selectedDate ? dayjs(selectedDate) : null} // Ensure correct format
                  onChange={handleDateChange}
                  className="form-control datetimepicker cal-icon"
                  format="DD-MM-YYYY"
                />
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  disabled={isAddSubmitDisabled}
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Edit Holiday Modal */}
      <div className="modal custom-modal fade" id="edit_holiday" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Holiday</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Holiday Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    value={updateHolidayName}
                    onChange={handleUpdateNameChange}
                    type="text"
                  />
                </div>
                <div
                  className="input-block mb-3"
                  style={{ position: "relative" }}
                >
                  <label className="col-form-label">
                    Holiday Date <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    value={updateHolidayDate} // Ensure correct format
                    onChange={handleUpdateDateChange}
                    className="form-control datetimepicker cal-icon"
                    format="DD-MM-YYYY"
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    disabled={isEditSubmitDisabled}
                    onClick={handleEditSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
