import React from "react";

const GlobalLoader = () => {
  return (
    <div className="page-wrapper">
      <span
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "calc(100vh - 60px)" }}
      >
        <div
          className="spinner-border avatar-lg text-primary me-1"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </span>
    </div>
  );
};

export default GlobalLoader;
