/* eslint-disable no-unused-expressions */

import { Table } from "antd";
import { AddHoliday } from "../../../components/modelpopup/AddHoliday";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Spinnerloaders from "../../../components/SpinnerLoader";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addHolidays,
  deleteHolidays,
  fetchHolidays,
  updateHolidays,
} from "../../../hooks/useHoliday";
import InfoToast from "../../layout/InfoToast";
import { formatDate } from "../../../assets/scss/utils/helpers/helper_functions";

//  ======================================Fetch holiday==========================================
const Holidays = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [selectedHolidayID, setSelectedHolidayID] = useState(null);
  const [selectedHolidayName, setSelectedHolidayName] = useState("");
  const [selectedHolidayDate, setSelectedHolidayDate] = useState(null);
  const [success, setSuccess] = useState("");
  const [toastType, setToastType] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 5000);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };
  //  ======================================Get holidays==========================================
  const { data, error, status, refetch } = useQuery({
    queryKey: ["holidays"],
    queryFn: fetchHolidays,
    refetchOnWindowFocus: false,
  });

  //  ======================================Add holiday==========================================
  const { mutate: addHolidayMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: addHolidays,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Holiday Added", type: "Added" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));

      setToastType({
        label: "An Error Occurred While Adding Holiday",
        type: "Error",
      });
    },
  });

  const addHolidayItem = (data) => {
    addHolidayMutation(data);
  };
  //  ======================================Update holiday==========================================
  const { mutate: updateHolidayMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateHolidays,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Holiday Updated", type: "Updated" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Updating Holiday",
        type: "Error",
      });
    },
  });

  const updateHolidayItem = (data) => {
    updateHolidayMutation({
      id: selectedHolidayID,
      name: data.name,
      holiday_date: data.holiday_date,
    });
  };
  //  ======================================Delete holiday==========================================

  const { mutate: DeleteHolidayMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: deleteHolidays,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));

      setToastType({ label: "Holiday Deleted", type: "Deleted" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));

      setToastType({
        label: "An Error Occurred While Deleting Holiday",
        type: "Error",
      });
    },
  });

  const deleteHolidayItem = (id) => {
    DeleteHolidayMutation(id);
  };  
  if (status === "error") {
    return (
      <div className="page-wrapper">
        <span className="content container-fluid">Error: {error.message}</span>
      </div>
    );
  }

  const userElements = data?.map((user, index) => {
    const holidayDate = new Date(user.holiday_date);
    const formattedDate = formatDate(holidayDate);
    const dayOfWeek = holidayDate.getDay();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = daysOfWeek[dayOfWeek];
    return {
      key: index,
      id: user.id,
      Title: user.name,
      HolidayDate: formattedDate,
      Day: dayName,
    };
  });

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {((pagination.current - 1) * pagination.pageSize) + index + 1}
        </span>
      ),
    },
    {
      title: "Title",
      dataIndex: "Title",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.Title.length - b.Title.length,
    },
    {
      title: "Holiday Date",
      dataIndex: "HolidayDate",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => {
        const dateA = new Date(a.HolidayDate);
        const dateB = new Date(b.HolidayDate);
        return dateB - dateA; // Descending order
      },
      defaultSortOrder: 'descend', // Default to descending
    },
    
    {
      title: "Day",
      dataIndex: "Day",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.Day.length - b.Day.length,
    },
    {
      title: "Action",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-center">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_holiday"
              onClick={() => {
                setSelectedHolidayID(record.id);
                setSelectedHolidayName(record.Title);
                setSelectedHolidayDate(record.HolidayDate);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => {
                setSelectedHolidayID(record.id);
              }}
            >
              <i className="fa-regular fa-trash-can m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      width: "7%",
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Holidays"
            title="Dashboard"
            subtitle="Holidays"
            modal="#add_holiday"
            name="Add Holiday"
          />

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={userElements?.length > 0 ? userElements : []}
                  className="table-striped"
                  rowKey={(record) => record.id}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </div>
              {isRefetching && <Spinnerloaders />}
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <AddHoliday
        addHolidayItem={addHolidayItem}
        updateHolidayItem={updateHolidayItem}
        selectedHolidayName={selectedHolidayName}
        selectedHolidayDate={selectedHolidayDate}
      />
      <DeleteModal
        Name="Delete Holiday"
        deleteDepartment={() => deleteHolidayItem(selectedHolidayID)}
      />
      <InfoToast
        success={success}
        handleClose={handleSolidPrimaryToastClose}
        toastType={toastType}
      />
    </>
  );
};

export default Holidays;
