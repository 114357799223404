import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import * as bootstrap from "bootstrap"; // Import Bootstrap as a module

// Define validation schema with Yup
const schema = yup.object().shape({
  departmentName: yup.string().required("Department Name is required"),
});

const DepartmentModal = (props) => {
  // For add department
  const {
    control: addControl,
    handleSubmit: handleAddSubmit,
    reset: resetAdd,
    formState: { errors: addErrors },
    watch: watchAdd,
  } = useForm({
    // resolver: yupResolver(schema),
    mode: "onChange",
  });

  // For edit department
  const {
    control: editControl,
    handleSubmit: handleEditSubmit,
    reset: resetEdit,
    setValue: setEditValue,
    formState: { errors: editErrors },
    watch: watchEdit,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  // Watch the departmentName field to control button disabling for Add form
  const departmentNameAdd = watchAdd("departmentName");

  // Watch the departmentName field to control button disabling for Edit form
  const departmentNameEdit = watchEdit("departmentName");

  // Set the initial value for the edit department modal when `props.selectedDep` changes
  useEffect(() => {
    if (props.selectedDep) {
      setEditValue("departmentName", props.selectedDep);
    } else {
      resetEdit(); // Clear the form if no department selected
    }
  }, [props.selectedDep, setEditValue, resetEdit]);

  // Handle form submission for adding a department
  const onAddSubmit = (data) => {
    props.addDepartment({ name: data.departmentName });
    resetAdd(); // Reset the form after submission
  };

  // Handle form submission for updating a department
  const onUpdateSubmit = (data) => {
    props.updateDepartment(data.departmentName);
    resetEdit(); // Reset the form after submission
  };

  useEffect(() => {
    const modalElement = document.getElementById("add_department");
    if (modalElement) {
      const modal = new bootstrap.Modal(modalElement);
      modal.hide();
    }
  }, []);

  return (
    <>
      {/* Add Department Modal */}
      <div
        id="add_department"
        className="modal custom-modal fade"
        role="dialog"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Department</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleAddSubmit(onAddSubmit)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department Name <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="departmentName"
                    control={addControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${
                          addErrors.departmentName ? "is-invalid" : ""
                        }`}
                        type="text"
                      />
                    )}
                  />
                  {addErrors.departmentName && (
                    <div className="invalid-feedback">
                      {addErrors.departmentName.message}
                    </div>
                  )}
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    data-bs-dismiss="modal"
                    disabled={!departmentNameAdd} // Enable button only when departmentNameAdd has a value
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Department Modal */}

      {/* Edit Department Modal */}
      <div
        id="edit_department"
        className="modal custom-modal fade"
        role="dialog"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Department</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleEditSubmit(onUpdateSubmit)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department Name <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="departmentName"
                    control={editControl}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${
                          editErrors.departmentName ? "is-invalid" : ""
                        }`}
                        type="text"
                      />
                    )}
                  />
                  {editErrors.departmentName && (
                    <div className="invalid-feedback">
                      {editErrors.departmentName.message}
                    </div>
                  )}
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    type="submit"
                    disabled={!departmentNameEdit} // Enable button only when departmentNameEdit has a value
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Department Modal */}
    </>
  );
};

export default DepartmentModal;
