import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  fetchCompanySettings,
  updateCompanySettings as updateSettingsApi,
} from "../../../../hooks/useSettings";
import InfoToast from "../../../layout/InfoToast";
import Spinnerloaders from "../../../../components/SpinnerLoader";

const Settings = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState({ label: "", type: "" });
  //  ======================================Get Settings==========================================
  const { data, refetch } = useQuery({
    queryKey: ["settings"],
    queryFn: fetchCompanySettings,
    refetchOnWindowFocus: false,
  });

  //  ======================================Update Settings==========================================
  const { mutate: updateSettingsPost } = useMutation({
    mutationFn: updateSettingsApi,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
      setTimeout(() => {
        setSuccess(true);
        setToastType({ label: "Settings Updated", type: "Updated" });
      }, 300);
    },

    onError: (error) => {
      setTimeout(() => {
        setToastType({
          label: "An Error Occurred While Updating Settings",
          type: "Failed",
        });
        setSuccess(true);
      }, 300);
    },
  });

  // State for managing form inputs
  const [formData, setFormData] = useState({
    company_name: "",
    contact_person: "",
    address: "",
    country: "",
    city: "",
    province: "",
    postal_code: "",
    email: "",
    phone: "",
    mobile: "",
    fax: "",
    website_url: "",
  });

  useEffect(() => {
    if (data) {
      setFormData({
        company_name: data.company_name,
        contact_person: data.contact_person,
        address: data.address,
        country: data.country,
        city: data.city,
        province: data.province,
        postal_code: data.postal_code,
        email: data.email,
        phone: data.phone,
        mobile: data.mobile,
        fax: data.fax,
        website_url: data.website_url,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateSettingsPost(formData);
    // setSuccess(true);
    // setToastType({ label: "Settings Updated", type: "Updated" });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };
 

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2 box-align">
              {/* Page Header */}
              <Breadcrumbs maintitle="Company Settings" />

              {/* /Page Header */}
              <form onSubmit={handleFormSubmit}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Company Name <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="company_name"
                        value={formData.company_name}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Contact Person</label>
                      <input
                        className="form-control"
                        type="text"
                        name="contact_person"
                        value={formData.contact_person}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Address</label>
                      <input
                        className="form-control"
                        type="text"
                        name="address"
                        value={formData.address}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Country</label>
                      <input
                        className="form-control"
                        type="text"
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">City</label>
                      <input
                        className="form-control"
                        type="text"
                        name="city"
                        value={formData.city}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">State/Province</label>
                      <input
                        className="form-control"
                        type="text"
                        name="province"
                        value={formData.province}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-6 col-lg-3">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Postal Code</label>
                      <input
                        className="form-control"
                        type="text"
                        name="postal_code"
                        value={formData.postal_code}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Email</label>
                      <input
                        className="form-control"
                        type="email"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Phone Number</label>
                      <input
                        className="form-control"
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Mobile Number</label>
                      <input
                        className="form-control"
                        type="text"
                        name="mobile"
                        value={formData.mobile}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Fax</label>
                      <input
                        className="form-control"
                        type="text"
                        name="fax"
                        value={formData.fax}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Website Url</label>
                      <input
                        className="form-control"
                        type="text"
                        name="website_url"
                        value={formData.website_url}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </div>
                {isRefetching && <Spinnerloaders/>}
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Save
                  </button>
                </div>
              </form>
              {success && (
                <div className="toast">
                  <div className={`toast-${toastType.type}`}>
                    {toastType.label}
                  </div>
                </div>
              )}
            </div>
            
          </div>
        </div>
        {/* /Page Content */}
      </div>
      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </>
  );
};

export default Settings;
