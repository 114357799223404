import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'

export const fetchEmployeeInfo = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`employees-detail?id=${id}`)
    return data.data;
}

export const addBankInformation = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`employees-bank`, payload)
    return data?.data;
}
export const addExperinceInformation = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`employees-experience`, payload)
    return data?.data;
}
export const addEducationInformation = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`employees-education`, payload)
    return data?.data;
}
export const addPersonalInformation = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`employees-personal-detail`, payload)
    return data?.data;
}
export const addEmergencyContactInformation = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`employees-emergency-contact`, payload)
    return data?.data;
}

// export const deleteEmployeeLeave = async (id) => {
//     setAuthenticationToken(localStorage.getItem('token'))
//     const { data } = await axiosInstance.delete(`employee-leave?id=${id}`)
//     return data?.data;
// }

// export const updateEmployeeLeave = async (res) => {
//     setAuthenticationToken(localStorage.getItem('token'))
//     const { data } = await axiosInstance.put(`employee-leave?id=${res.id}`,res)
//     return data?.data;
// }