import React, { useState, useEffect } from "react";
import Select from "react-select";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const leaveSchema = yup.object().shape({
  employee: yup.object().required("Employee is required"),
  leaveType: yup.object().required("Leave Type is required"),
  fromDate: yup.date().required("From date is required").nullable(),
  toDate: yup
    .date()
    .required("To date is required")
    .nullable()
    .min(yup.ref("fromDate"), "To date should not be less than From date"),
  remainingLeaves: yup
    .number()
    .typeError("Remaining leaves must be a number")
    .required("Remaining leaves is required")
    .positive("Remaining leaves must be greater than 0"),
  leaveReason: yup.string().required("Leave reason is required"),
});

const EmployeeLeaveModelPopup = ({
  leaveTypeList,
  employeeList,
  updateEmployeeData,
  addEmployeeLeaveData,
  selectedId,
  selectedLeave,
}) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [leaveType, setLeaveType] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [leaveReason, setLeaveReason] = useState("");

  useEffect(() => {
    if (selectedLeave) {
      // Set From and To Dates
      setFromDate(new Date(selectedLeave.from));
      setToDate(new Date(selectedLeave.to));

      // Set Leave Type
      const matchedLeaveType = leaveTypeList.find(
        (type) => type.id === selectedLeave.leave_id
      );
      setLeaveType(
        matchedLeaveType
          ? { value: matchedLeaveType.id, label: matchedLeaveType.type }
          : null
      );

      // Set Employee
      const matchedEmployee = employeeList.find(
        (emp) => emp.id === selectedLeave.employee_id
      );
      setEmployee(
        matchedEmployee
          ? {
              value: matchedEmployee.id,
              label: `${matchedEmployee.firstname} ${
                matchedEmployee.lastname || ""
              }`,
            }
          : null
      );

      // Set Leave Reason
      setLeaveReason(selectedLeave.reason);
    } else {
      resetForm();
    }
  }, [selectedLeave, leaveTypeList, employeeList]);

  const resetForm = () => {
    setFromDate(null);
    setToDate(null);
    setLeaveType(null);
    setEmployee(null);
    setLeaveReason("");
  };

  const leavetypeOptions = leaveTypeList?.map((item) => ({
    value: item.id,
    label: item.type,
  }));

  const employeeOptionsList = employeeList?.map((item) => ({
    value: item.id,
    label: `${item.firstname || ""} ${item.lastname || ""}`,
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const leaveData = {
      id: selectedId,
      employee: employee ? employee.value : null,
      leave_type: leaveType ? leaveType.value : null,
      from: dayjs(fromDate).format("YYYY-MM-DD"),
      to: dayjs(toDate).format("YYYY-MM-DD"),
      status: "Pending",
      reason: leaveReason,
    };
    addEmployeeLeaveData(leaveData);
    resetForm();
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();
    const leaveData = {
      id: selectedId,
      employee: employee ? employee.value : null,
      leave_type: leaveType ? leaveType.value : null,
      from: dayjs(fromDate).format("YYYY-MM-DD"),
      to: dayjs(toDate).format("YYYY-MM-DD"),
      status: "Pending",
      reason: leaveReason,
    };
    updateEmployeeData(leaveData);
    resetForm();
  };
  const [isValid, setisValid] = useState(false);

  useEffect(() => {
    const allFieldsFilled =
      fromDate && toDate && leaveType && employee && leaveReason;

    setisValid(allFieldsFilled);
  }, [fromDate, toDate, leaveType, employee, leaveReason]);

  return (
    <>
      <div id="add_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Leave</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Employee <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={employeeOptionsList}
                    placeholder="Select"
                    styles={customStyles}
                    // value={employee}
                    onChange={setEmployee}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={leavetypeOptions}
                    placeholder="Select"
                    styles={customStyles}
                    // value={leaveType}
                    onChange={setLeaveType}
                  />
                </div>

                <div
                  className="input-block mb-3"
                  style={{ position: `relative` }}
                >
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    // value={fromDate ? dayjs(fromDate) : null}
                    onChange={(date) =>
                      setFromDate(date ? date.toDate() : null)
                    }
                    className="form-control datetimepicker cal-icon"
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </div>
                <div
                  className="input-block mb-3"
                  style={{ position: `relative` }}
                >
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    // value={toDate ? dayjs(toDate) : null}
                    onChange={(date) => setToDate(date ? date.toDate() : null)}
                    className="form-control datetimepicker cal-icon"
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Reason <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control"
                    // value={leaveReason}
                    onChange={(e) => setLeaveReason(e.target.value)}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    type="submit"
                    disabled={!isValid}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="edit_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Leave</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleEditSubmit}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Employee <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={employeeOptionsList}
                    placeholder="Select"
                    styles={customStyles}
                    value={employee}
                    onChange={setEmployee}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={leavetypeOptions}
                    placeholder="Select"
                    styles={customStyles}
                    value={leaveType}
                    onChange={setLeaveType}
                  />
                </div>
                <div
                  className="input-block mb-3"
                  style={{ position: `relative` }}
                >
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    value={fromDate ? dayjs(fromDate) : null}
                    onChange={(date) =>
                      setFromDate(date ? date.toDate() : null)
                    }
                    className="form-control datetimepicker cal-icon"
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </div>
                <div
                  className="input-block mb-3"
                  style={{ position: `relative` }}
                >
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    value={toDate ? dayjs(toDate) : null}
                    onChange={(date) => setToDate(date ? date.toDate() : null)}
                    className="form-control datetimepicker cal-icon"
                    format="DD-MM-YYYY"
                    getPopupContainer={(trigger) => trigger.parentElement}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Reason <span className="text-danger">*</span>
                  </label>
                  <textarea
                    rows={4}
                    className="form-control"
                    value={leaveReason}
                    onChange={(e) => setLeaveReason(e.target.value)}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    aria-label="Close"
                    data-bs-dismiss="modal"
                    type="submit"
                    disabled={!isValid}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeLeaveModelPopup;
