import React, { useEffect, useState } from "react";
import Select from "react-select";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const EditAttendanceModal = (props) => {
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");
  const [attendanceDate, setAttendanceDate] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  useEffect(() => {
    if (props.data) {
      setEmployeeList(
        props.data?.employees?.map((item) => ({
          value: item.uuid,
          label: `${item.firstname} ${item.lastname || ""}`,
        }))
      );
    }
  }, [props.data]);

  const handleCheckInTimeChange = (e) => {
    setCheckInTime(e.target.value);
  };

  const handleCheckOutTimeChange = (e) => {
    setCheckOutTime(e.target.value);
  };

  const handleAttendanceChange = (date, dateString) => {
    setAttendanceDate(date);
  };

  const handleEmployeeChange = (selectedOption) => {
    setSelectedEmployee(selectedOption);
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    props.addEmployeeAttendanceItem({
      checkin: checkInTime,
      checkout: checkOutTime,
      employee: selectedEmployee.value,
      status: "late",
      date: attendanceDate ? attendanceDate.format("YYYY-MM-DD") : "",
    });
    setCheckInTime("");
    setCheckOutTime("");
    setAttendanceDate(null);
    setSelectedEmployee(null);
  };

  const handleEditSubmit = (e) => {
    e.preventDefault();

    props.updateAttendanceItem({
      checkin: checkInTime,
      checkout: checkOutTime,
      employee: props.employeeId,
    });
    setCheckInTime("");
    setCheckOutTime("");
    setAttendanceDate(null);
    setSelectedEmployee(null);
  };

  useEffect(() => {
    setCheckInTime(props.selectedAttendanceCheckIn);
    setCheckOutTime(props.selectedAttendanceCheckOut);
  }, [props.selectedAttendanceCheckIn, props.selectedAttendanceCheckOut]);

  return (
    <>
      <div
        id="add_attendance"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Attendance</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Employee Name <span className="text-danger">*</span>
                  </label>
                  <Select
                    options={employeeList}
                    placeholder="Select Employee"
                    styles={customStyles}
                    onChange={handleEmployeeChange}
                    value={selectedEmployee}
                  />
                </div>
                <div
                  className="input-block mb-3"
                  style={{ position: `relative` }}
                >
                  <label className="col-form-label">
                    Date <span className="text-danger">*</span>
                  </label>
                  <DatePicker
                    className="form-control datetimepicker cal-icon"
                    onChange={handleAttendanceChange}
                    getPopupContainer={(trigger) => trigger.parentElement}
                    value={attendanceDate}
                    format="YYYY-MM-DD"
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Check In <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="time"
                    value={checkInTime}
                    onChange={handleCheckInTimeChange}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Check Out <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="time"
                    value={checkOutTime}
                    onChange={handleCheckOutTimeChange}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        id="edit_attendance"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Attendance</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleEditSubmit}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Check In <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="time"
                    name="checkInTime"
                    value={checkInTime}
                    onChange={handleCheckInTimeChange}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Check Out <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="time"
                    name="checkOutTime"
                    value={checkOutTime}
                    onChange={handleCheckOutTimeChange}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={handleEditSubmit}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAttendanceModal;
