/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useQuery } from "@tanstack/react-query";
import { fetchEmployeeInfo } from "../../../hooks/useEmployeeMoreInfo";
import Spinnerloaders from "../../../components/SpinnerLoader";
import { formatDate } from "../../../assets/scss/utils/helpers/helper_functions";

const Profile = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [loadingName, setLoadingName] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const {
    data: userData,
    error,
    status,
    refetch,
  } = useQuery({
    queryKey: ["fetchEmployeeInfo", id],
    queryFn: () => fetchEmployeeInfo(id),
    refetchOnWindowFocus: false,
    onSuccess: () => {
      setLoadingName(false);
    },
    onError: () => {
      setLoadingName(false);
    },
  });

  useEffect(() => {
    if (status === "success") {
      setLoadingName(false);
    }
  }, [userData]);


  if (status === "loading" || isRefetching) {
    return (
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Spinnerloaders />
        </div>
      </div>
    );
  }

  if (status === "error") {
    return (
      <div className="page-wrapper">
        <span className="content container-fluid">Error: {error.message}</span>
      </div>
    );
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Profile"
            title="Dashboard"
            subtitle="Profile"
            modal="#add_indicator"
            name="Add New"
          />
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    {loadingName ? (
                      <Spinnerloaders /> 
                    ) : (
                      <>
                        <div className="profile-img-wrap">
                          <div className="profile-img">
                            <Link to="#">
                              <img
                                src={userData?.avatar || "default-avatar.png"}
                                alt="User Image"
                              />
                            </Link>
                          </div>
                        </div>
                        <div className="profile-basic">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="profile-info-left">
                                <h3 className="user-name m-t-0 mb-0">
                                  {userData?.firstname} {userData?.lastname}
                                </h3>
                                <h6 className="text-muted">{userData?.role}</h6>
                                <small className="text-muted">
                                  {userData?.jobTitle}
                                </small>
                                <div className="staff-id">
                                  Employee ID : TXP-{userData?.uuid}
                                </div>
                                <div className="staff-id">
                                  Employee Department :{" "}
                                  {userData?.department_name}
                                </div>
                                <div className="staff-id">
                                  Employee Designation :{" "}
                                  {userData?.designation_name}
                                </div>
                                <div className="small doj text-muted">
                                  Date of Join : {formatDate(userData?.joining_date)}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-7">
                              <ul className="personal-info">
                                <li>
                                  <div className="title">Phone:</div>
                                  <div className="text">
                                    <Link to={`tel:${userData?.phone}`}>
                                      {userData?.phone}
                                    </Link>
                                  </div>
                                </li>
                                <li>
                                  <div className="title">Company Email:</div>
                                  <div className="text">
                                    <Link
                                      to={`mailto:${userData?.company_email}`}
                                    >
                                      {userData?.company_email}
                                    </Link>
                                  </div>
                                </li>
                                <li>
                                  <div className="title">Personal Email:</div>
                                  <div className="text">
                                    <Link to={`mailto:${userData?.email}`}>
                                      {userData?.email}
                                    </Link>
                                  </div>
                                </li>
                                <li>
                                  <div className="title">Birthday:</div>
                                  <div className="text">
                                    {formatDate(userData?.birthday)}
                                  </div>
                                </li>
                                <li>
                                  <div className="title">Gender:</div>
                                  <div className="text">{userData?.gender}</div>
                                </li>
                                <li>
                                  <div className="title">Address:</div>
                                  <div className="text">
                                    {userData?.address}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card tab-box">
            <div className="row user-tabs">
              <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <Link
                      to="#emp_profile"
                      data-bs-toggle="tab"
                      className="nav-link active"
                    >
                      Profile
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* Profile Info Tab */}
          <ProfileTab data={userData} refetch={refetch} />
        </div>
      </div>
    </>
  );
};

export default Profile;
