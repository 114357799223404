import { Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";

const LeaveSettingCustomPolicy = ({
  customPolicies,
  addPolicy,
  leaveTypeId,
  setSelectedLeaveTypeId,
  setUpdateItemId,
  setdeleteItemId,
}) => {
  const columns = [
    {
      title: "Name",
      dataIndex: "policy_name",
      render: (text, record) => <span>{record.policy_name}</span>,
    },
    {
      title: "Day",
      dataIndex: "days",
      render: (text, record) => <span>{record.days}</span>,
    },
    {
      title: "Assignee",
      dataIndex: "employees",
      render: (text, record) => (
        <span className="table-avatar">
          {record?.employees && record?.employees.length > 0
            ? record?.employees
                ?.map(
                  (employee) => `${employee.firstname} ${employee.lastname}`
                )
                .join(", ")
            : ""}
        </span>
      ),
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_custom_policy"
              onClick={() => {
                setUpdateItemId(record.id);
                setSelectedLeaveTypeId(leaveTypeId);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete_custom_policy"
              onClick={() => {
                setdeleteItemId(record.id);
                setSelectedLeaveTypeId(leaveTypeId);
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="custom-policy">
        <div className="leave-header">
          <div className="title">Custom policy</div>
          <div className="leave-action">
            <button
              className="btn btn-sm btn-primary"
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#add_custom_policy"
              onClick={() => setSelectedLeaveTypeId(leaveTypeId)}
            >
              <i className="fa fa-plus" /> Add custom policy
            </button>
          </div>
        </div>
        <div className="table-responsive">
          <Table
            columns={columns}
            dataSource={customPolicies}
            className="table-striped"
            rowKey={(record) => record.id}
          />
        </div>
      </div>
    </div>
  );
};

export default LeaveSettingCustomPolicy;
