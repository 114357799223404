import React, { useEffect, useState } from "react";
import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AttendanceEmployeeFilter from "../../../components/AttendanceEmployeeFilter";
import { Link } from "react-router-dom";
import EditAttendanceModal from "../../../components/modelpopup/EditAttendanceModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addEmployeeAttendancePost,
  fetchEmployeeAttendance,
  fetchSearchEmployeeAttendance,
  updateEmployeeAttendance,
} from "../../../hooks/useAttendence";
import InfoToast from "../../layout/InfoToast";
import { formatDate } from "../../../assets/scss/utils/helpers/helper_functions";
import Spinnerloaders from "../../../components/SpinnerLoader";

const AttendanceEmployee = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [selectedAttendanceID, setSelectedAttendanceID] = useState(null);
  const [searchResults, setSearchResults] = useState([null]);
  const [selectedAttendanceCheckIn, setSelectedAttendanceCheckIn] =
    useState(null);
  const [selectedAttendanceCheckOut, setSelectedAttendanceCheckOut] =
    useState(null);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  //  ======================================Get Attendance==========================================
  const { data, refetch } = useQuery({
    queryKey: ["attendance"],
    queryFn: fetchEmployeeAttendance,
  });

  //  ======================================Add Attendance==========================================
  const { mutate: addEmployeeAttendanceMutation } = useMutation({
    mutationFn: addEmployeeAttendancePost,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
      setTimeout(() => {
        setSuccess(true);
        setToastType({ label: "Employee Attendance Added", type: "Added" });
      }, 300);
    },
    onError: (error) => {
      setTimeout(() => {
        setToastType({
          label: "An Error Occurred While Adding Employee Attendance",
          type: "Error",
        });
        setSuccess(true);
      }, 300);
    },
  });

  const addEmployeeAttendanceItem = (data) => {
    addEmployeeAttendanceMutation(data);
  };

  //  ======================================Update Attendance==========================================
  const { mutate: updateAttendanceMutation } = useMutation({
    mutationFn: updateEmployeeAttendance,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
      setTimeout(() => {
        setSuccess(true);
        setToastType({ label: "Employee Attendance Updated", type: "Updated" });
      }, 300);
    },
    onError: (error) => {
      setTimeout(() => {
        setToastType({
          label: "An Error Occurred While Updating Employee Attendance",
          type: "Error",
        });
        setSuccess(true);
      }, 300);
    },
  });

  const updateAttendanceItem = (data) => {
    updateAttendanceMutation({
      id: selectedAttendanceID,
      checkin: data.checkin,
      checkout: data.checkout,
      employee: data.employee,
    });
  };

  //  ======================================Search Attendance==========================================

  const { mutate: SearchEmployeeAttendance } = useMutation({
    mutationFn: fetchSearchEmployeeAttendance,
    onSuccess: (data) => {
      setSearchResults(data);
    },
    onError: (error) => {
      setToastType({
        label: "An Error Occurred While Searching Employees",
        type: "Error",
      });
      setSuccess(true);
    },
  });

  const searchEmployees = (filters) => {
    SearchEmployeeAttendance(filters);
  };

  const userElements = (
    searchResults?.attendance ||
    data?.attendance ||
    []
  ).map((item) => ({
    key: item.id,
    employee_id: item.employee_id,
    id: item.id,
    Date: item.date,
    PunchIn: item.checkin,
    PunchOut: item.checkout,
    employee: item.employee_id,
    firstname: item?.employee?.firstname,
    lastname: item?.employee?.lastname,
    // Production: item.Production,
    // Break: item.Break,
    // Overtime: item.Overtime,
  }));

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {((pagination.current - 1) * pagination.pageSize) + index + 1}
        </span>
      ),
    },

    {
      title: "Emp ID",
      dataIndex: "employee_id",
      render: (text, record) => (
        <div className="table-avatar">
          <span>TXP-{record?.employee_id}</span>
        </div>
      ),
      sorter: (a, b) => a.employee_id.length - b.employee_id.length,
    },
    {
      title: "Name",
      dataIndex: "firstName",
      render: (text, record) => (
        <div className="table-avatar">
          <span>
            {record?.firstname} {record?.lastname}
          </span>
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Date",
      dataIndex: "Date",
      render: (text) => <span>{formatDate(text)}</span>,
      sorter: (a, b) => {
        const dateA = new Date(a.Date);
        const dateB = new Date(b.Date);
        return dateB - dateA; // Ascending order
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: "In",
      dataIndex: "PunchIn",
      sorter: (a, b) => a.PunchIn.length - b.PunchIn.length,
    },
    {
      title: "Out",
      dataIndex: "PunchOut",
      sorter: (a, b) => a.PunchOut.length - b.PunchOut.length,
    },
    // {
    //   title: "Break",
    //   dataIndex: "Break",
    //   sorter: (a, b) => a.Break.length - b.Break.length,
    // },
    // {
    //   title: "Overtime",
    //   dataIndex: "Overtime",
    //   sorter: (a, b) => a.Overtime.length - b.Overtime.length,
    // },
    {
      title: "Action",
      className: "text-center",
      render: (_, record) => (
        <div className="">
          <Link
            className=""
            to="#"
            data-bs-toggle="modal"
            data-bs-target="#edit_attendance"
            onClick={() => {
              setSelectedAttendanceID(record.id);
              setSelectedAttendanceCheckIn(record.PunchIn);
              setSelectedAttendanceCheckOut(record.PunchOut);
              setEmployeeId(record.employee);
            }}
          >
            <i className="fa fa-pencil m-r-5" /> Edit
          </Link>
        </div>
      ),
      width: "10%",
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        {/* /Page Header */}
        <div className="content container-fluid ">
          <div className="relative">
            <Breadcrumbs
              maintitle="Attendance"
              title="Dashboard"
              subtitle="Attendance"
              modal="#add_attendance"
              name="Add Attendance"
            />
            {/* <Button type="button"
            className="top-0 absolute right-o"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#add_attendance">
              Add Attendance
            </Button> */}
          </div>

          {/* /Page Header */}
          <div className="row">
            {/* <div className="col-md-4">
              <div className="card punch-status">
                <div className="card-body">
                  <h5 className="card-title">
                    Timesheet <small className="text-muted">11 Mar 2023</small>
                  </h5>
                  <div className="punch-det">
                    <h6>Punch In at</h6>
                    <p>Wed, 11th Mar 2023 10.00 AM</p>
                  </div>
                  <div className="punch-info">
                    <div className="punch-hours">
                      <span>3.45 hrs</span>
                    </div>
                  </div>
                  <div className="punch-btn-section">
                    <button type="button" className="btn btn-primary punch-btn">
                      Punch Out
                    </button>
                  </div>
                  <div className="statistics">
                    <div className="row">
                      <div className="col-md-6 col-6 text-center">
                        <div className="stats-box">
                          <p>Break</p>
                          <h6>1.21 hrs</h6>
                        </div>
                      </div>
                      <div className="col-md-6 col-6 text-center">
                        <div className="stats-box">
                          <p>Overtime</p>
                          <h6>3 hrs</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card att-statistics">
                <div className="card-body">
                  <h5 className="card-title">Statistics</h5>
                  <div className="stats-list">
                    {Array.isArray(users) && users.length > 0 ? (
                      users.map((data, index) => (
                        <div className="stats-info" key={index}>
                          <p>
                            {data.title}{" "}
                            <strong>
                              {data.value} <small>{data.valuespan}</small>
                            </strong>
                          </p>
                          <div className="progress">
                            <div
                              className={`progress-bar ${data.progressBarColor}`}
                              role="progressbar"
                              style={{ width: data.progressWidth }}
                              aria-valuenow={data.progressWidth}
                              aria-valuemin={0}
                              aria-valuemax={100}
                            />
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No data available</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-4">
              <div className="card recent-activity">
                <div className="card-body">
                  <h5 className="card-title">Today Activity</h5>
                  <ul className="res-activity-list">
                    {Array.isArray(activity) && activity.length > 0 ? (
                      activity.map((activity, index) => (
                        <li key={index}>
                          <p className="mb-0">{activity.title}</p>
                          <p className="res-activity-time">
                            <i className="fa-regular fa-clock"></i>{" "}
                            {activity.time}
                          </p>
                        </li>
                      ))
                    ) : (
                      <p>No activities available.</p>
                    )}
                  </ul>
                </div>
              </div>
            </div> */}

            <AttendanceEmployeeFilter searchEmployees={searchEmployees} />

            <div className="row">
              <div className="col-lg-12">
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    dataSource={userElements}
                    className="table-striped"
                    rowKey={(record) => record.id}
                    pagination={pagination}
                    onChange={handleTableChange}
                  />
                </div>
                {isRefetching && <Spinnerloaders/>}
              </div>
            </div>
          </div>
        </div>
      </div>
      <EditAttendanceModal
        addEmployeeAttendanceItem={addEmployeeAttendanceItem}
        updateAttendanceItem={updateAttendanceItem}
        selectedAttendanceID={selectedAttendanceID}
        selectedAttendanceCheckIn={selectedAttendanceCheckIn}
        selectedAttendanceCheckOut={selectedAttendanceCheckOut}
        employeeId={employeeId}
        data={data}
      />
      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </>
  );
};

export default AttendanceEmployee;
