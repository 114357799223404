import React, { useEffect } from "react";
import { DatePicker } from "antd";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";

const leaveSchema = yup.object().shape({
  employee: yup.object().required("Employee is required"),
  leaveType: yup.object().required("Leave Type is required"),
  fromDate: yup.date().required("From date is required").nullable(),
  toDate: yup
    .date()
    .required("To date is required")
    .nullable()
    .min(yup.ref("fromDate"), "To date should not be less than From date"),
  remainingLeaves: yup
    .number()
    .typeError("Remaining leaves must be a number")
    .required("Remaining leaves is required")
    .positive("Remaining leaves must be greater than 0"),
  leaveReason: yup.string().required("Leave reason is required"),
});

export const AdminLeaveAddModelPopup = ({
  leaveTypes,
  editable,
  employees,
  updateEmployeeData,
  addEmployeeData,
  selectedEmployee,
}) => {
  const leaves = leaveTypes?.map((leaveType) => {
    return { value: leaveType.id, label: leaveType.type };
  });
  const employeeDetails = employees?.map((emp) => {
    return {
      value: emp.id,
      label: (emp.firstname  || "") + " " + (emp?.lastname || ""),
    };
  });

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(leaveSchema),
    mode: "onChange",
    defaultValues: {
      employee:null,
      leaveType: null,
      fromDate: null,
      toDate: null,
      remainingLeaves: 12,
      leaveReason: "",
    },
  });

  useEffect(() => {
    reset();
    if (editable) {
      setValue(
        "employee",
        {
          value: selectedEmployee?.employee?.id,
          label:
            selectedEmployee?.employee?.firstname +
            " " +
            (selectedEmployee?.employee?.lastname || ''),
        } || null
      );
      setValue(
        "leaveType",
        {
          value: selectedEmployee?.leave_type?.id,
          label: selectedEmployee?.leave_type?.type,
        } || null
      );
      setValue(
        "fromDate",
        selectedEmployee.from ? dayjs(selectedEmployee.from) : null
      );
      setValue(
        "toDate",
        selectedEmployee.to ? dayjs(selectedEmployee.to) : null
      );
      setValue("leaveReason", selectedEmployee.reason || "");
      setValue("remainingLeaves", 12);
    }
  }, [editable, reset, setValue, selectedEmployee]);

  const onSubmit = (data) => {
    const payload = {
      employee: data.employee.value,
      leave_type: data.leaveType.value,
      from: dayjs(data.fromDate).format("YYYY-MM-DD"),
      to: dayjs(data.toDate).format("YYYY-MM-DD"),
      reason: data.leaveReason,
      status: "pending",
    };
  
    if (data.remainingLeaves <= 0) {
      // Handle case where remainingLeaves is not greater than 0
      // You might want to display an error message or take appropriate action
      return;
    }
  
    if (editable) {
      const payloadWithId = { ...payload, id: selectedEmployee.id };
      updateEmployeeData(payloadWithId);
    } else {
      addEmployeeData(payload);
    }
  
    // Reset the form fields to their default values
    reset();
  };

  return (
    <>
      <div id="add_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Leave</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Select Employee <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="employee"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={employeeDetails}
                        placeholder="Select"
                        styles={customStyles}
                      />
                    )}
                  />
                  {errors.employee && (
                    <p className="text-danger">{errors.employee.message}</p>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="leaveType"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={leaves}
                        placeholder="Select"
                        styles={customStyles}
                      />
                    )}
                  />
                  {errors.leaveType && (
                    <p className="text-danger">{errors.leaveType.message}</p>
                  )}
                </div>
                <div
                  className="input-block mb-3"
                  style={{ position: `relative` }}
                >
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="fromDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(date) =>
                          field.onChange(date ? dayjs(date).toDate() : null)
                        }
                        className="form-control datetimepicker cal-icon"
                        format="DD-MM-YYYY"
                        disabledDate={(current) =>
                          current && current < dayjs().startOf("day")
                        }
                        getPopupContainer={(trigger) => trigger.parentElement}
                      />
                    )}
                  />
                  {errors.fromDate && (
                    <p className="text-danger">{errors.fromDate.message}</p>
                  )}
                </div>
                <div
                  className="input-block mb-3"
                  style={{ position: `relative` }}
                >
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="toDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(date) =>
                          field.onChange(date ? dayjs(date).toDate() : null)
                        }
                        className="form-control datetimepicker cal-icon"
                        format="DD-MM-YYYY"
                        disabledDate={(current) =>
                          current &&
                          current <
                            (getValues("fromDate")
                              ? dayjs(getValues("fromDate")).startOf("day")
                              : dayjs().startOf("day"))
                        }
                        getPopupContainer={(trigger) => trigger.parentElement}
                      />
                    )}
                  />
                  {errors.toDate && (
                    <p className="text-danger">{errors.toDate.message}</p>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Remaining Leaves <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="remainingLeaves"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-control"
                        type="number"
                        defaultValue={12}
                      />
                    )}
                  />
                  {errors.remainingLeaves && (
                    <p className="text-danger">
                      {errors.remainingLeaves.message}
                    </p>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Reason <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="leaveReason"
                    control={control}
                    render={({ field }) => (
                      <textarea {...field} rows={4} className="form-control" />
                    )}
                  />
                  {errors.leaveReason && (
                    <p className="text-danger">{errors.leaveReason.message}</p>
                  )}
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={!isValid}
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="edit_leave" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Leave</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Select Employee <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="employee"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={employeeDetails}
                        placeholder="Select"
                        styles={customStyles}
                      />
                    )}
                  />
                  {errors.leaveType && (
                    <p className="text-danger">{errors.leaveType.message}</p>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="leaveType"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={leaves}
                        placeholder="Select"
                        styles={customStyles}
                      />
                    )}
                  />
                  {errors.leaveType && (
                    <p className="text-danger">{errors.leaveType.message}</p>
                  )}
                </div>
                <div
                  className="input-block mb-3"
                  style={{ position: `relative` }}
                >
                  <label className="col-form-label">
                    From <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="fromDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(date) =>
                          field.onChange(date ? dayjs(date).toDate() : null)
                        }
                        className="form-control datetimepicker cal-icon"
                        format="DD-MM-YYYY"
                        disabledDate={(current) =>
                          current && current < dayjs().startOf("day")
                        }
                        getPopupContainer={(trigger) => trigger.parentElement}
                      />
                    )}
                  />
                  {errors.fromDate && (
                    <p className="text-danger">{errors.fromDate.message}</p>
                  )}
                </div>
                <div
                  className="input-block mb-3"
                  style={{ position: `relative` }}
                >
                  <label className="col-form-label">
                    To <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="toDate"
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        value={field.value ? dayjs(field.value) : null}
                        onChange={(date) =>
                          field.onChange(date ? dayjs(date).toDate() : null)
                        }
                        className="form-control datetimepicker cal-icon"
                        format="DD-MM-YYYY"
                        disabledDate={(current) =>
                          current &&
                          current <
                            (getValues("fromDate")
                              ? dayjs(getValues("fromDate")).startOf("day")
                              : dayjs().startOf("day"))
                        }
                        getPopupContainer={(trigger) => trigger.parentElement}
                      />
                    )}
                  />
                  {errors.toDate && (
                    <p className="text-danger">{errors.toDate.message}</p>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Remaining Leaves <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="remainingLeaves"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className="form-control"
                        type="number"
                        defaultValue={12}
                      />
                    )}
                  />
                  {errors.remainingLeaves && (
                    <p className="text-danger">
                      {errors.remainingLeaves.message}
                    </p>
                  )}
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Reason <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="leaveReason"
                    control={control}
                    render={({ field }) => (
                      <textarea {...field} rows={4} className="form-control" />
                    )}
                  />
                  {errors.leaveReason && (
                    <p className="text-danger">{errors.leaveReason.message}</p>
                  )}
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={!isValid}
                    data-bs-dismiss="modal"
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
