import React, { useState, useEffect } from "react";
import Select from "react-select";

const AddUserModal = ({ createNewUser }) => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    password_confirmation: "",
    phone: "",
    role: "",
    company: "",
    employeeId: "",
  });

  const [isFormValid, setIsFormValid] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleRoleChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      role: selectedOption ? selectedOption.label : "",
    }));
  };

  const handleCompanyChange = (selectedOption) => {
    setFormData((prevData) => ({
      ...prevData,
      company: selectedOption ? selectedOption.label : "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const updatedFormData = {
      ...formData,
      employeeId: parseInt(formData.employeeId, 10),
    };

    createNewUser(updatedFormData);
  };

  useEffect(() => {
    const allFieldsFilled =
      formData.firstname &&
      formData.lastname &&
      formData.email &&
      formData.password &&
      formData.password_confirmation &&
      formData.phone &&
      formData.role &&
      formData.company &&
      formData.employeeId;

    const passwordsMatch = formData.password === formData.password_confirmation;

    setIsFormValid(allFieldsFilled && passwordsMatch);
  }, [formData]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const optionsTwo = [
    { value: 1, label: "Xpert Prime" },
    // { value: 2, label: "Global Technologies" },
    // { value: 3, label: "Delta Infotech" },
  ];

  const options = [
    { value: 1, label: "Admin" },
    { value: 2, label: "Client" },
    { value: 3, label: "Employee" },
  ];

  return (
    <div id="add_user" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add User</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="firstname"
                      value={formData.firstname}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="lastname"
                      value={formData.lastname}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Employee ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control floating"
                      name="employeeId"
                      value={formData.employeeId}
                      onChange={(e) => {
                        // Handle the input change, making sure to only accept non-negative values
                        const value = Math.max(0, e.target.value); // Ensure non-negative value
                        setFormData((prevData) => ({
                          ...prevData,
                          [e.target.name]: value,
                        }));
                      }}
                      min="0" // Prevent negative values from being entered
                      required
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Password <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Confirm Password <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      name="password_confirmation"
                      value={formData.password_confirmation}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Phone <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="phone"
                      value={formData.phone}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Role <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Role"
                      onChange={handleRoleChange}
                      options={options}
                      className="select floating"
                      styles={customStyles}
                      isClearable
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Company <span className="text-danger">*</span>
                    </label>
                    <Select
                      placeholder="Select Company"
                      onChange={handleCompanyChange}
                      options={optionsTwo}
                      className="select floating"
                      styles={customStyles}
                      isClearable
                    />
                  </div>
                </div>
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  type="submit"
                  disabled={!isFormValid}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
