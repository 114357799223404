import { useEffect, useState } from 'react';
import { axiosInstance } from './axios';
import { setAuthenticationToken } from "./auth";

// Handles the login request
export const loginRequest = async (payload) => {
    try {
        const { data } = await axiosInstance.post('login', payload);
        return data?.data;
    } catch (error) {
        console.error('Login request failed:', error);
        throw error;
    }
};
export const checkMail = async (res) => {
    setAuthenticationToken(localStorage.getItem("token"));
    const { data } = await axiosInstance.post(`forgot-password`, res);
    return data?.data;
  };
export const verifyOtp = async (res) => {
    setAuthenticationToken(localStorage.getItem("token"));
    const { data } = await axiosInstance.post(`verify-otp`, res);
    return data?.data;
  };
export const createNewPass = async (res) => {
    setAuthenticationToken(localStorage.getItem("token"));
    const { data } = await axiosInstance.post(`set-password`, res);
    return data?.data;
  };
// Custom hook to check authentication status
// const useAuth = () => {
//     const [isAuthenticated, setIsAuthenticated] = useState(false);

//     useEffect(() => {
//         const token = localStorage.getItem('token');
//         if (token) {
//             setIsAuthenticated(true);
//             localStorage.setItem('authCheck', 'true');
//         } else {
//             setIsAuthenticated(false);
//         }
//     }, []); // Empty dependency array ensures this runs only once when the component mounts

//     return isAuthenticated;
// };

// export default useAuth;
