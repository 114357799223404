import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'

export const fetchAllEmployeesLeave = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`employee-leave`)
    return data.data;
}

export const addEmployeeLeave = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`employee-leave`, payload)
    return data?.data;
}

export const deleteEmployeeLeave = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`employee-leave?id=${id}`)
    return data?.data;
}

export const updateEmployeeLeave = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`employee-leave?id=${res.id}`,res)
    return data?.data;
}
export const updateEmployeeLeaveStatus = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`employee-leave?id=${res.id}`,res)
    return data?.data;
}

