import { axiosInstance } from "./axios";
import { setAuthenticationToken } from "./auth";

export const fetchAllEmployees = async () => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.get(`employees-list`);
  return data.data;
};
export const fetchEmployee = async () => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.get(`designations`);
  return data.data;
};
export const addEmployee = async (payload) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.post(`employees`, payload);
  return data?.data;
};
export const deleteEmployee = async (id) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.delete(`employees?id=${id}`);
  return data?.data;
};
export const updateEmployeePost = async (res) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.post(`employees-update`, res);
  return data?.data;
};
export const searchEmployee = async (res) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.post(`search-employees`, res);
  return data?.data;
};
export const fetchEmployeeID = async (filters) => {
  try {
    setAuthenticationToken(localStorage.getItem("token"));

    const { employee_id, name, department, designation } = filters;

    const params = new URLSearchParams();
    if (employee_id) params.append("employee_id", employee_id);
    if (name) params.append("name", name);
    if (department) params.append("department", department);
    if (designation) params.append("designation", designation);

    const queryString = params.toString();

    if (!queryString) {
      throw new Error("Query string is empty. No valid parameters provided.");
    }

    const { data } = await axiosInstance.get(`employees-list?${queryString}`);
    return data?.data;
  } catch (error) {
    console.error("Error fetching employee data:", error);
    throw error;
  }
};
