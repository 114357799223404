export const formatDate = (input) => {
    const date = new Date(input);
    
    if (isNaN(date.getTime())) {
      throw new Error('Invalid date');
    }
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

// Disable Edit or click buttons
export const disableClick = (isDisabled, onClick, e) => {
  if (isDisabled) {
    e.preventDefault(); // Prevent default action if disabled
    return;
  }
  
  if (onClick) {
    onClick(e); // Call the passed onClick handler
  }
};
