import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Define the validation schema using yup
const validationSchema = yup.object().shape({
  type: yup.string().required("Leave Type is required"),
  days: yup
    .number()
    .typeError("Number of days must be a number")
    .required("Number of days is required"),
  is_active: yup.number(),
});

const LeaveTypeModal = ({
  selectedType,
  addLeaveTypeData,
  updateLeaveTypeData,
}) => {
  // Initialize react-hook-form with the validation schema
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      type: "",
      days: "",
      is_active: '1',
    },
  });

  useEffect(() => {
    reset();
    if (selectedType) {
      setValue("type", selectedType.type || "");
      setValue("days", selectedType.days || "");
      setValue("is_active", selectedType.is_active || 1);
    }
  }, [selectedType, reset, setValue]);

  const onSubmit = (formData) => {
   
    const payload = {
      type: formData.type,
      days: formData.days,
      is_active: formData.is_active.toString()
    }
    addLeaveTypeData(payload);
  };
  const onUpdate = (formData) => {
    
    const payload = {
      id: selectedType.id,
      type: formData.type,
      days: formData.days,
      is_active: formData.is_active.toString()
    }
    updateLeaveTypeData(payload);
  };

  return (
    <>
      {/* Add Leavetype Modal */}
      <div id="add_leavetype" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Leave Type</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className={`form-control ${
                            errors.type ? "is-invalid" : ""
                          }`}
                          type="text"
                        />
                        {errors.type && (
                          <p className="text-danger">{errors.type.message}</p>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="days"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className={`form-control ${
                            errors.days ? "is-invalid" : ""
                          }`}
                          type="text"
                        />
                        {errors.days && (
                          <p className="text-danger">{errors.days.message}</p>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">Status</label>
                  <Controller
                    name="is_active"
                    control={control}
                    render={({ field }) => (
                      <div className="ant-table-row ant-table-row-level-0 float-right">
                        <Link
                          className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                          to="#"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {field.value != 0 ? (
                            <>
                              <i className="far fa-dot-circle text-success" />{" "}
                              Active
                            </>
                          ) : (
                            <>
                              <i className="far fa-dot-circle text-danger" />{" "}
                              Inactive
                            </>
                          )}
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link
                            to="#"
                            className="dropdown-item"
                            onClick={() => field.onChange('1')}
                          >
                            <i className="far fa-dot-circle text-success" />{" "}
                            Active
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                            onClick={() => field.onChange('0')}
                          >
                            <i className="far fa-dot-circle text-danger" />{" "}
                            Inactive
                          </Link>
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    data-bs-dismiss="modal"
                    disabled={!isValid}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Leavetype Modal */}
      {/* Edit Leavetype Modal */}
      <div
        id="edit_leavetype"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Leave Type</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onUpdate)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Leave Type <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className={`form-control ${
                            errors.type ? "is-invalid" : ""
                          }`}
                          type="text"
                        />
                        {errors.type && (
                          <p className="text-danger">{errors.type.message}</p>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Number of days <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="days"
                    control={control}
                    render={({ field }) => (
                      <>
                        <input
                          {...field}
                          className={`form-control ${
                            errors.days ? "is-invalid" : ""
                          }`}
                          type="text"
                        />
                        {errors.days && (
                          <p className="text-danger">{errors.days.message}</p>
                        )}
                      </>
                    )}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">Status</label>
                  <Controller
                    name="is_active"
                    control={control}
                    render={({ field }) => (
                      <div className="ant-table-row ant-table-row-level-0 float-right">
                        <Link
                          className="btn btn-white btn-sm btn-rounded dropdown-toggle"
                          to="#"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {field.value != 0 ? (
                            <>
                              <i className="far fa-dot-circle text-success" />{" "}
                              Active
                            </>
                          ) : (
                            <>
                              <i className="far fa-dot-circle text-danger" />{" "}
                              Inactive
                            </>
                          )}
                        </Link>
                        <div className="dropdown-menu dropdown-menu-right">
                          <Link
                            to="#"
                            className="dropdown-item"
                            onClick={() => field.onChange('1')}
                          >
                            <i className="far fa-dot-circle text-success" />{" "}
                            Active
                          </Link>
                          <Link
                            to="#"
                            className="dropdown-item"
                            onClick={() => field.onChange('0')}
                          >
                            <i className="far fa-dot-circle text-danger" />{" "}
                            Inactive
                          </Link>
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    data-bs-dismiss="modal"
                    disabled={!isValid}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Leavetype Modal */}
    </>
  );
};

export default LeaveTypeModal;
