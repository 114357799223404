import React, { useEffect, useState } from "react";
import Select from "react-select";

const AddDesingnationModelPopup = (props) => {
  const [designation, setDesignation] = useState([]);
  const [designationName, setDesignationName] = useState("");
  const [selectedDepartment, setSelectedDepartment] =
    useState("Select Department");
  const [updDesignation, setUpdDesignation] = useState(
    props.selectedDesignation
  );

  useEffect(() => {
    setUpdDesignation(props.selectedDesignation);
  }, [props.selectedDesignation]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
    setUpdDesignation((prev) => ({
      ...prev,
      department: selectedOption,
    }));
  };
  useEffect(() => {
    const data = props?.departments?.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    });
    setDesignation(data);
  }, [props.departments]);

  return (
    <>
      <div
        id="add_designation"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Designation</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Designation Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={designationName}
                    onChange={(event) => {
                      setDesignationName(event.target.value);
                    }}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department <span className="text-danger">*</span>
                  </label>

                  <Select
                    options={designation}
                    placeholder="Select Department"
                    styles={customStyles}
                    onChange={handleDepartmentChange}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    disabled={
                      !selectedDepartment.label || designationName === ""
                        ? true
                        : false
                    }
                    onClick={() => {
                      props.addDesignation({
                        designation: designationName,
                        department: selectedDepartment.value,
                      });
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="edit_designation"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Designation</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Designation Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={updDesignation.designation}
                    onChange={(event) => {
                      setUpdDesignation({
                        ...updDesignation,
                        designation: event.target.value,
                      });
                    }}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Department <span className="text-danger">*</span>
                  </label>

                  <Select
                    options={designation}
                    placeholder="Select Department"
                    styles={customStyles}
                    value={updDesignation.department}
                    onChange={handleDepartmentChange}
                  />
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary "
                    data-bs-dismiss="modal"
                    type="button"
                    onClick={() => {
                      props.updateDesignation(updDesignation);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDesingnationModelPopup;
