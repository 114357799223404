import { setAuthenticationToken } from "./auth";
import { axiosInstance } from "./axios";

export const fetchCompanySettings = async () => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.get(`settings-company`);
  return data?.data;
};
export const readLeaveSettings = async () => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.get(`employee-leave-policy`);
  return data;
};
export const fetchThemeSettings = async () => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.get(`settings-theme`);
  return data?.data;
};
export const fetchAttendanceSettings = async () => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.get(`settings-attendance`);
  return data?.data;
};
export const addEmployeeCustomPolicy = async (res) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.post(`employee-leave-policy`, res);
  return data?.data;
};
export const updateAttendanceSettings = async (res) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.post(`settings-attendance`, res);
  return data?.data;
};
export const updateCompanySettings = async (res) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.post(`settings-company`, res);
  return data?.data;
};
export const updateThemeSettings = async (res) => {

  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.post(`settings-theme`, res);
  return data?.data;
};
export const updateEmployeeCustomPolicy = async (res) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.put(`departments?id=${res.id}`, res);
  return data?.data;
};
export const deleteEmployeeCustomPolicy = async (id) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.delete(`employee-leave-policy?id=${id}`);
  return data?.data;
};
