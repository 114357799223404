import React, { useState } from "react";
import { Link } from "react-router-dom";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";
import RolesPermissionsModal from "../../../../components/modelpopup/RolesPermissionsModal";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addRole, deleteRole, fetchPermissionsAgainstRoles, fetchRoles, updateRole } from "../../../../hooks/useRolePermissions";
import InfoToast from "../../../layout/InfoToast";

const RolesPermissions = () => {
  const[isRefetching,setIsRefetching] = useState(true)
  const[success,setSuccess] = useState(false)
  const [toastType, setToastType] = useState("");
  const [roleID, setRoleID] = useState("");
  const [ID, setID] = useState("");
console.log(ID, "ROleser codetas")




  const tableData = [
    {
      category: "Employee",
      items: ["Item 1", "Item 2", "Item 3", "Item 4",],
    },
    {
      category: "Holidays",
      items: ["Item 1", "Item 2", "Item 3", "Item 4",],
    },
    {
      category: "Leaves",
      items: ["Item 1", "Item 2", "Item 3", "Item 4",],
    },
    {
      category: "Events",
      items: ["Item 1", "Item 2", "Item 3", "Item 4",],
    },
  ];
  const notificationItems = [
    { id: "staff_module", label: "Employee", defaultChecked: false },
    { id: "holidays_module", label: "Holidays", defaultChecked: true },
    { id: "leave_module", label: "Leaves", defaultChecked: true },
    { id: "events_module", label: "Events", defaultChecked: true },
    { id: "chat_module", label: "Chat", defaultChecked: true },
    { id: "job_module", label: "Jobs", defaultChecked: false },
  ];



 
    //  ======================================Get Permissions against Roles==========================================
    const {
      data: permissionsData,
      error,
      status: permissionsStatus,
      refetch: permissionsRefetch,
    } = useQuery({
      queryKey: ["fetchPermissionsAgainstRoles", ID],
      queryFn: () => fetchPermissionsAgainstRoles(ID),
        enabled: !!ID, // Query will run only when ID is set (non-null and non-undefined)
        refetchOnWindowFocus: false,
    });

    console.log(permissionsData, "data of per")

    //  ======================================Get Roles==========================================
    const { data, status, refetch } = useQuery({
      queryKey: ["roles"],
      queryFn: fetchRoles,
        refetchOnWindowFocus: false,
    });

    //  ======================================Add Roles==========================================
  const { mutate: addRoleMutation } = useMutation({
    mutationFn: addRole,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
      setTimeout(() => {
        setSuccess(true);
        setToastType({ label: "User Role Added", type: "Added" });
      }, 300);
    },
    onError: (error) => {
      setTimeout(() => {
        setToastType({
          label: "An Error Occurred While Adding User Role.",
          type: "Error",
        });
        setSuccess(true);
      }, 300);
    },
  });
    //  ======================================Update Role==========================================
    const { mutate: updateRoleMutation } = useMutation({
      mutationFn: updateRole,
      onSuccess: () => {
        setIsRefetching(true);
        refetch().then(() => setIsRefetching(false));
        setTimeout(() => {
          setSuccess(true);
          setToastType({ label: "User Role Updated", type: "Updated" });
        }, 300);
      },
      onError: (error) => {
        setTimeout(() => {
          setToastType({
            label: "An Error Occurred While Updating User Role",
            type: "Error",
          });
          setSuccess(true);
        }, 300);
      },
    });
    // ========================================Del Department===========================
  const { mutate: delRoleMutation } = useMutation({
    mutationFn: deleteRole,
    onMutate: () => {
      setIsRefetching(true);
    },
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "User Role Deleted", type: "Deleted" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Deleting User Role",
        type: "Error",
      });
    },
  });
  
  
  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs maintitle="Roles & Permissions" />

          {/* /Page Header */}
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-3">
              <Link
                to="#"
                className="btn btn-primary btn-block w-100"
                data-bs-toggle="modal"
                data-bs-target="#add_role"
              >
                <i className="fa fa-plus" /> Add Roles
              </Link>
              <div className="roles-menu">
                <ul>
                  {data?.map((role, index) => (
                    <li key={index} className={index === 0 ? "active" : ""}>
                      <Link to="#" 
                      onClick={() => setID(role?.id)}
                      >
                        {role.name}
                        <span className="role-action">
                          <span
                            className="action-circle large me-1"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_role"
                            onClick={() => setRoleID(role)}
                          >
                            <i className="material-icons">edit</i>
                          </span>
                          <span
                            className="action-circle large delete-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#delete"
                            onClick={() => setRoleID(role)}
                          >
                            <i className="material-icons">delete</i>
                          </span>
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-9">
              {/* <h6 className="card-title m-b-20">Module Access</h6> */}
              {/* <div className="m-b-30">
                <ul className="list-group notification-list">
                  {notificationItems.map((item) => (
                    <li key={item.id} className="list-group-item">
                      {item.label}
                      <div className="status-toggle">
                        <input
                          type="checkbox"
                          id={item.id}
                          className="check"
                          defaultChecked={item.defaultChecked}
                        />
                        <label htmlFor={item.id} className="checktoggle">
                          checkbox
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div> */}
              <div className="table-responsive">
                <table className="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <th className="text-center">Read</th>
                      <th className="text-center">Write</th>
                      <th className="text-center">Create</th>
                      <th className="text-center">Delete</th>
                      {/* <th className="text-center">Import</th>
                      <th className="text-center">Export</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((categoryData, index) => (
                      <tr key={index}>
                        <td>{categoryData.category}</td>
                        {categoryData.items.map((item, itemIndex) => (
                          <td key={itemIndex} className="text-center">
                            <label className="custom_check">
                              <input type="checkbox" defaultChecked="Checked" />
                              <span className="checkmark" />
                            </label>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        {success && (
        <InfoToast
          success={success}
          toastType={toastType}
        />
      )}
        <RolesPermissionsModal addRole={addRoleMutation} updateRole={updateRoleMutation} selectedRole={roleID}/>
        <DeleteModal Name="Role"  deleteDepartment={() => delRoleMutation(roleID.id)}/>
      </div>
    </>
  );
};

export default RolesPermissions;
