import React from "react";
import { Link } from "react-router-dom";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import { ListItem, ProjectDetails } from "./ProfileContent";
import NoDataMessage from "./NoDataMessage";

const ProfileTab = ({ data, refetch }) => {
  const personalInfoData = [
    { id: 1, title: "CNIC No", text: data?.cnic },
    { id: 2, title: "Nationality", text: data?.nationality },
    { id: 3, title: "Marital status", text: data?.martial_status },
    { id: 4, title: "Father/Husband Name", text: data?.father_husband_name },
    { id: 5, title: "Number Of Dependents:", text: data?.no_of_dependents },
    {
      id: 6,
      title: "Father Occupation:",
      text: data?.father_occupation,
    },
    { id: 7, title: "Blood Group:", text: data?.blood_group },
    { id: 8, title: "Any Disability", text: data?.disability },
  ];

  const primaryContactData = [
    {
      id: 1,
      title: "Name: ",
      text: data?.employee_emergency_contact_detail[0]?.name,
    },
    {
      id: 2,
      title: "Relation:",
      text: data?.employee_emergency_contact_detail[0]?.relation,
    },
    {
      id: 3,
      title: "Phone: ",
      text: data?.employee_emergency_contact_detail[0]?.phone,
    },
  ];
  const secondaryContactData = [
    {
      id: 1,
      title: "Name: ",
      text: data?.employee_emergency_contact_detail[1]?.name,
    },
    {
      id: 2,
      title: "Relation:",
      text: data?.employee_emergency_contact_detail[1]?.relation,
    },
    {
      id: 3,
      title: "Phone: ",
      text: data?.employee_emergency_contact_detail[1]?.phone,
    },
  ];

  const bankInfoData = [
    { id: 1, title: "Bank Name", text: data?.employee_bank?.[0]?.bank_name },
    {
      id: 2,
      title: "Bank Account No.",
      text: data?.employee_bank?.[0]?.account_no,
    },
    { id: 3, title: "Bank Title", text: data?.employee_bank?.[0]?.title },
  ];

  const formatDateRange = (startDate, endDate) => {
    const start = typeof startDate === "string" ? startDate.slice(0, 4) : "";
    const end = typeof endDate === "string" ? endDate.slice(0, 4) : "";
    return `${start} - ${end}`;
  };

  const educationData = data?.employee_education?.map((item) => {
    return {
      id: item.id,
      name: item.institute,
      description: item.degree,
      time: formatDateRange(item.start_date, item.end_date),
      grade: item.grade,
    };
  });

  const experienceData = data?.employee_experience?.map((item) => {
    return {
      id: item.id,
      name: item.position + " at " + item.company_name,
      time: formatDateRange(item.start_date, item.end_date),
      location: item.location,
    };
  });

  return (
    <>
      <div className="tab-content">
        <div
          id="emp_profile"
          className="pro-overview tab-pane fade show active"
        >
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Personal Informations{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#personal_info_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="personal-info">
                        {personalInfoData?.map((item, index) => (
                          <ListItem
                            id={item.id}
                            key={index}
                            title={item.title}
                            text={item.text}
                          />
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Emergency Contact{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#emergency_contact_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <ul className="personal-info">
                    {primaryContactData?.map((item, index) => (
                      <ListItem
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                      />
                    ))}
                  </ul>
                  <hr />
                  <ul className="personal-info">
                    {secondaryContactData?.map((item, index) => (
                      <ListItem
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Education Informations{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#education_info"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    {educationData?.length ? (
                      <ul className="experience-list">
                        {educationData?.map((item) => (
                          <li key={item.id}>
                            <div className="experience-user">
                              <div className="before-circle" />
                            </div>
                            <div className="experience-content">
                              <div className="timeline-content">
                                {item.name}
                                <div>
                                  {" "}
                                  {item.description} &nbsp;Grade:&nbsp;
                                  {item.grade}
                                </div>
                                <span className="time">{item.time}</span>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <NoDataMessage />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body ">
                  <h3 className="card-title">
                    Experience{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#experience_info"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <div className="experience-box">
                    {experienceData?.length ? (
                      <ul className="experience-list">
                        {experienceData.map((item) => (
                          <li key={item.id}>
                            <div className="experience-user">
                              <div className="before-circle" />
                            </div>
                            <div className="experience-content">
                              <div className="timeline-content">
                                {item.name}
                                <div>{item.location}</div>
                                <span className="time">{item.time}</span>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <NoDataMessage />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Bank information{" "}
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#bank-information-modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <ul className="personal-info">
                    {bankInfoData?.map((item, index) => (
                      <ListItem
                        data={data}
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProjectDetails />
        {/*  Bank Tab */}
      </div>
      {/* Model Popup*/}
      <PersonalInformationModelPopup
        data={data}
        employee_bank={data?.employee_bank}
        employee_contact={data?.employee_emergency_contact_detail}
        employee_education={data?.employee_education}
        employee_experience={data?.employee_experience}
        refetch={refetch}
      />
    </>
  );
};

export default ProfileTab;
