import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AttendenceModelPopup from "../../../components/modelpopup/AttendenceModelPopup";
import * as bootstrap from 'bootstrap'; // Import Bootstrap as a module
import MonthlyAttendanceFilter from "../../../components/MonthlyAttendanceFilter";

const TableAvatar = ({ data, setInitialFilters }) => {
  const [modalData, setModalData] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleIconClick = (employee, dayIndex) => {
    setLoading(true);
    setTimeout(() => {
      setModalData({
        employee,
        dayIndex,
        attendance: employee.attendance[dayIndex],
      });
      setLoading(false);
    }, 200); // Adjust the timeout duration as needed
  };

  useEffect(() => {
    if (modalData && !loading) {
      const modalElement = document.getElementById('attendance_info');
      if (modalElement) {
        const modal = new bootstrap.Modal(modalElement);
        modal.show();
      }
    }
  }, [modalData, loading]);

  return (
    <>
      <MonthlyAttendanceFilter setInitialFilters={setInitialFilters}/>
      <div className="table-container">
      <table className="table thead-fixed table-striped custom-table table-nowrap gap-10 mb-10">
        <thead className="mt-20">
          <tr className="mt-20" style={{marginTop:"20px"}}>
            <th className="th-1" style={{textAlign:"left"}}>Employee</th>
            {Array.from({ length: 31 }, (_, index) => (
              <th  style={{textAlign:"center"}} key={index + 1}>{index + 1}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data?.map((employee, index) => (
            <tr key={index}>
              <td>
                <h2 className="table-avatar">
                  <Link className="avatar avatar-xs" to={`/profile?id=${employee?.id}`}>
                    <img alt={employee.firstname} src={employee.avatar} />
                  </Link>
                  <Link to={`/profile?id=${employee?.id}`}>{employee.firstname} {employee.lastname}</Link>
                </h2>
              </td>
              {Array.from({ length: 31 }, (_, dayIndex) => (
                <td key={dayIndex}>
                  {employee.attendance[dayIndex]?.type === "weekdays" ? (
                    <Link
                      to="#"
                      onClick={() => handleIconClick(employee, dayIndex)}
                    >
                      <i className="fa-regular fa-check-circle text-success" />
                    </Link>
                  ) : employee.attendance[dayIndex]?.type === "absent" ? (
                    <Link
                      to="#"
                    // onClick={() => handleIconClick(employee, dayIndex)}
                    >
                      <i className="fa-regular fa-times-circle text-danger" />
                    </Link>
                  ) : employee.attendance[dayIndex]?.type === "weekend" ? (
                    <Link
                      to="#"
                    // onClick={() => handleIconClick(employee, dayIndex)}
                    >
                      <i className="fa-regular fa-calendar-alt  text-info" />
                    </Link>
                  ) : employee.attendance[dayIndex]?.type === "holiday" ? (
                    <Link
                      to="#"
                    // onClick={() => handleIconClick(employee, dayIndex)}
                    >
                      <i className="fa-regular fa-sun text-warning" />
                    </Link>
                  )
                    : employee.attendance[dayIndex]?.type === "leave" ? (
                      <Link
                        to="#"
                      // onClick={() => handleIconClick(employee, dayIndex)}
                      >
                        <i className="fas fa-sign-out-alt text-primary" />
                      </Link>
                    ) : (
                      <Link
                        to="#"
                      // onClick={() => handleIconClick(employee, dayIndex)}
                      >
                        <i className="fa fa-minus text-secondary" />
                      </Link>
                    )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      {modalData && !loading && (
        <AttendenceModelPopup
          data={modalData}
          id="attendance_info"
        />
      )}
    </>
  );
};

export default TableAvatar;
