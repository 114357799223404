import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import "../assets/css/searchBarButtons.css";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const customStyles = {
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999, // Ensure menu is above other elements
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 9999, // Ensure menu is above other elements
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
    color: state.isFocused ? "#fff" : "#000",
    "&:hover": {
      backgroundColor: "#ff9b44",
    },
  }),
};

const monthOptions = [
  { value: 1, label: "January" },
  { value: 2, label: "February" },
  { value: 3, label: "March" },
  { value: 4, label: "April" },
  { value: 5, label: "May" },
  { value: 6, label: "June" },
  { value: 7, label: "July" },
  { value: 8, label: "August" },
  { value: 9, label: "September" },
  { value: 10, label: "October" },
  { value: 11, label: "November" },
  { value: 12, label: "December" },
];

const InputField = ({ label, children }) => (
  <div className="col-sm-3">
    <div className="input-block form-focus select-focus">
      {children}
      <label className="focus-label">{label}</label>
    </div>
  </div>
);

const MonthlyAttendanceFilter = ({ setInitialFilters }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [employeeName, setEmployeeName] = useState("");

  const handleMonthChange = (option) => setSelectedMonth(option);
  const handleYearChange = (date, dateString) => setSelectedYear(dateString);
  const handleNameChange = (e) => setEmployeeName(e.target.value);

  const handleSearch = () => {
    const month = selectedMonth ? selectedMonth.value : "";
    const year = selectedYear || "";
    setInitialFilters({
      employee_name: employeeName,
      month: month,
      year: year,
    });
  };

  const handleReset = () => {
    setEmployeeName("");
    setSelectedMonth(null);
    setSelectedYear(null);
    setInitialFilters({
      employee_name: "",
      month: "",
      year: "",
    });
  };

  return (
    <div className="row filter-row">
      <InputField label="Employee Name">
        <input
          type="text"
          className="form-control floating"
          value={employeeName}
          onChange={handleNameChange}
        />
      </InputField>
      <InputField label="Select Month">
        <Select
          options={monthOptions}
          placeholder="Select"
          value={selectedMonth}
          styles={customStyles}
          onChange={handleMonthChange}
          menuPortalTarget={document.body} // Ensures menu is attached to the body
        />
      </InputField>
      <InputField label="Select Year">
        <DatePicker
          picker="year"
          className="form-control floating datetimepicker"
          onChange={handleYearChange}
          value={selectedYear ? dayjs(selectedYear, "YYYY") : null}
          placeholder="Select Year"
        />
      </InputField>
      <div className="col-sm-2 mb-2 containerStyle">
        <Link to="#" onClick={handleSearch} className="me-2 linkStyle">
          <BsSearch className="iconStyle" />
        </Link>
        <Link to="#" onClick={handleReset} className="linkStyle">
          <MdOutlineSettingsBackupRestore className="iconStyle" />
        </Link>
      </div>
    </div>
  );
};

export default MonthlyAttendanceFilter;
