// axiosInstance.js
import axios from 'axios';
import { useHistory } from 'react-router-dom'; // Import useHistory

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const setAuthToken = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axiosInstance.defaults.headers.common['Authorization'];
  }
};

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // Handle 401 errors here
      // Use history object to redirect to login page
      window.location.href = '/'; // Redirects to login page
    }
    return Promise.reject(error);
  }
);

export { axiosInstance, setAuthToken };
