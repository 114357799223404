import React, { useState, useEffect } from "react";
import LeaveSettingCustomPolicy from "./LeaveSettingCustomPolicy";
import LeaveSettingAddModelPopup from "../../../components/modelpopup/LeaveSettingAddModelPopup";
import LeaveSettingEditPopup from "../../../components/modelpopup/LeaveSettingEditPopup";
import LeaveSettingDeletePopup from "../../../components/modelpopup/LeaveSettingDeletePopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Spinnerloaders from "../../../components/SpinnerLoader";
import InfoToast from "../../layout/InfoToast";
import {
  addEmployeeCustomPolicy,
  deleteEmployeeCustomPolicy,
  readLeaveSettings,
  updateEmployeeCustomPolicy,
} from "../../../hooks/useSettings";
import { useMutation, useQuery } from "@tanstack/react-query";

const LeaveSettings = () => {
  const [selectedLeaveTypeId, setSelectedLeaveTypeId] = useState(null);
  const [updateItemId, setUpdateItemId] = useState(null);
  const [deleteItemId, setdeleteItemId] = useState(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  const { data, error, status, refetch } = useQuery({
    queryKey: ["leaveSettings"],
    queryFn: readLeaveSettings,
    refetchOnWindowFocus: false,
    select: (data) => data.data,
  });

  // Ensure data is an array before proceeding
  const leaveSettingsData = Array.isArray(data) ? data : [];

  // ==================================Add Employee Custom Policy================================

  const { mutate: addCustomPolicy } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: addEmployeeCustomPolicy,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(setSuccess(true));
      setToastType({ label: "Policy added successfully", type: "Added" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(setSuccess(true));
      setToastType({
        label: "An Error Occurred While Adding Policy",
        type: "Error",
      });
      console.error("Error adding policy:", error);
    },
  });

  const addPolicy = (policy) => {
    addCustomPolicy(policy);
  };

  // ==================================Updated Employee Custom Policy==================================

  const { mutate: updateCustomPolicy } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateEmployeeCustomPolicy,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(setSuccess(true));
      setToastType({
        label: "Employee Custom Policy Updated successfully",
        type: "Updated",
      });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(setSuccess(true));
      setToastType({
        label: "An Error Occurred While Updating Policy",
        type: "Error",
      });
      console.error("An Error Occurred While Updating Policy:", error);
    },
  });

  const editPolicy = (updatedPolicy) => {
    updateCustomPolicy(updatedPolicy);
  };

  // ==================================Delete Employee Custom Policy================================

  const { mutate: deleteCustomPolicy } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: deleteEmployeeCustomPolicy,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(setSuccess(true));
      setToastType({
        label: "Employee Custom Policy Deleted successfully",
        type: "Deleted",
      });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(setSuccess(true));
      setToastType({
        label: "An Error Occurred While Deleting Employee Custom Policy",
        type: "Error",
      });
      console.error(
        "An Error Occurred While Deleting Employee Custom Policy:",
        error
      );
    },
  });

  const deletePolicy = (id) => {
    deleteCustomPolicy(id);
  };

  // Filter the custom policy for the selected leave type
  const selectedPolicy = leaveSettingsData
    .find((item) => item.id === selectedLeaveTypeId)
    ?.custom_policies?.find((policy) => policy.id === updateItemId);

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Leave Settings"
            title="Dashboard"
            subtitle="Leave Settings"
            modal="#add_custom_policy"
            name="Add New"
          />
          <div className="row">
            <div className="col-md-12">
              {leaveSettingsData.map((item, index) => (
                <div className="card leave-box " id="leave_annual" key={index}>
                  <div className="card-body">
                    <div className="h3 card-title with-switch">
                      {item.type}
                      <div className="onoffswitch">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          id={`switch_custom${index}`}
                          defaultChecked
                        />
                        <label className="onoffswitch-label">
                          <span className="onoffswitch-inner" />
                          <span className="onoffswitch-switch" />
                        </label>
                      </div>
                    </div>
                    <div className="leave-item">
                      <div className="leave-row">
                        <div className="leave-left">
                          <div className="input-box">
                            <div className="input-block">
                              <label>Days</label>
                              <input
                                type="text"
                                className="form-control"
                                disabled
                                value={item.days}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {status === "loading" ? (
                      <p>Loading...</p>
                    ) : status === "error" ? (
                      <p>Error: {error.message}</p>
                    ) : (
                      <LeaveSettingCustomPolicy
                        customPolicies={item.custom_policies}
                        addPolicy={addPolicy}
                        leaveTypeId={item.id}
                        setSelectedLeaveTypeId={setSelectedLeaveTypeId}
                        setUpdateItemId={setUpdateItemId}
                        setdeleteItemId={setdeleteItemId}
                      />
                    )}
                    {isRefetching && <Spinnerloaders />}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <LeaveSettingAddModelPopup
        leaveTypeId={selectedLeaveTypeId}
        addPolicy={addPolicy}
      />
      <LeaveSettingEditPopup
        leaveTypeId={selectedLeaveTypeId}
        updateItemId={updateItemId}
        policy={selectedPolicy}
        editPolicy={editPolicy}
      />
      <LeaveSettingDeletePopup
        leaveTypeId={selectedLeaveTypeId}
        deleteItemId={deleteItemId}
        deletePolicy={deletePolicy}
      />
      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </>
  );
};

export default LeaveSettings;
