import { setAuthenticationToken } from './auth'
import { axiosInstance } from './axios'


export const fetchHolidays = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`holidays`);
    return data?.data;
}

export const addHolidays = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`holidays`, payload)   
   return data?.data;
}

export const updateHolidays = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`holidays?id=${res.id}&name=${res.name}&holiday_date=${res.holiday_date}`)
    return data?.data;
}

export const deleteHolidays = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`holidays?id=${id}`)
    return data?.data;
}