import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Applogo } from "../../../../Routes/ImagePath/index";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchThemeSettings,
  updateThemeSettings,
} from "../../../../hooks/useSettings";
import GlobalLoader from "../../../layout/GlobalLoader";
import InfoToast from "../../../layout/InfoToast";

const ThemeSettings = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState({ label: "", type: "" });
  const [formData, setFormData] = useState({
    site_name: "",
    currency_symbol: "",
    currency_code: "",
    logo: null,
    favicon: null,
  });

  useEffect(() => {
    const timeoutId = setTimeout(() => setSuccess(false), 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => setSuccess(false);

  const { data, error, status, refetch } = useQuery({
    queryKey: ["theme-settings"],
    queryFn: fetchThemeSettings,
    refetchOnWindowFocus: false,
  });

  const { mutate: updateSettingsPost } = useMutation({
    mutationFn: updateThemeSettings,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
      setTimeout(() => {
        setSuccess(true);
        setToastType({ label: "Theme Settings Updated", type: "Updated" });
      }, 300);
    },
    onError: () => {
      setTimeout(() => {
        setToastType({
          label: "An Error Occurred While Updating Theme Settings",
          type: "Error",
        });
        setSuccess(true);
      }, 300);
    },
  });

  useEffect(() => {
    if (data) {
      setFormData({
        site_name: data.site_name,
        currency_symbol: data.currency_symbol,
        currency_code: data.currency_code,
        logo: data.logo || null,
        favicon: data.favicon || null,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files[0],
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const updatedData = {
      site_name: formData.site_name,
      currency_symbol: formData.currency_symbol,
      currency_code: formData.currency_code,
      logo: formData.logo,
      favicon: formData.favicon,
    };
    updateSettingsPost(updatedData);
  };

  if (status === "loading" || isRefetching) {
    return <GlobalLoader />;
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2 box-align">
              <Breadcrumbs maintitle="Theme Settings" />
              <form onSubmit={handleFormSubmit}>
                <div className="input-block row">
                  <label className="col-lg-3 col-form-label">Website Name</label>
                  <div className="col-lg-9">
                    <input
                      name="site_name"
                      className="form-control"
                      value={formData.site_name}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                </div>
                <div className="input-block row">
                  <label className="col-lg-3 col-form-label">Currency Symbol</label>
                  <div className="col-lg-9">
                    <input
                      name="currency_symbol"
                      className="form-control"
                      value={formData.currency_symbol}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                </div>
                <div className="input-block row">
                  <label className="col-lg-3 col-form-label">Currency Code</label>
                  <div className="col-lg-9">
                    <input
                      name="currency_code"
                      className="form-control"
                      value={formData.currency_code}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                </div>
                <div className="input-block row">
                  <label className="col-lg-3 col-form-label">Light Logo</label>
                  <div className="col-lg-7">
                    <input
                      type="file"
                      name="logo"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                    <span className="form-text text-muted">
                      Recommended image size is 40px x 40px
                    </span>
                  </div>
                  <div className="col-lg-2">
                    {formData.logo && (
                      <div className="img-thumbnail float-end">
                        <img
                          src={
                            formData.logo instanceof File
                              ? URL.createObjectURL(formData.logo)
                              : Applogo
                          }
                          alt="Logo"
                          width={40}
                          height={40}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="input-block row">
                  <label className="col-lg-3 col-form-label">Favicon</label>
                  <div className="col-lg-7">
                    <input
                      type="file"
                      name="favicon"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                    <span className="form-text text-muted">
                      Recommended image size is 16px x 16px
                    </span>
                  </div>
                  <div className="col-lg-2">
                    {formData.favicon && (
                      <div className="settings-image img-thumbnail float-end">
                        <img
                          src={
                            formData.favicon instanceof File
                              ? URL.createObjectURL(formData.favicon)
                              : Applogo
                          }
                          className="img-fluid"
                          alt="Favicon"
                          width={16}
                          height={16}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </>
  );
};

export default ThemeSettings;
