import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Popover } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { AdminLeaveAddModelPopup } from "../../../components/modelpopup/AdminLeaveModelPopup";
import SearchBox from "../../../components/SearchBox";
import Spinnerloaders from "../../../components/SpinnerLoader";
import { formatDate } from "../../../assets/scss/utils/helpers/helper_functions";
import {
  deleteAttendanceRequest,
  fetchAttendanceRequests,
  editAttendanceRequest,
  updateAttendenceRequestStatus,
} from "../../../hooks/useAttendence";
import InfoToast from "../../layout/InfoToast";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EditAttendanceRequestPopup from "../../../components/modelpopup/EditAttendanceRequestModalPopup";
import dayjs from "dayjs";

const AttendenceRequest = () => {
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [selectedId, setSelectedId] = useState({});
  const [isRefetching, setIsRefetching] = useState(false);
  const [editable, setEditable] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedEmoId, setSelectedEmoId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedReason, setSelectedReason] = useState("");
  const [feedback, setFeedback] = useState("");
  const [id, setId] = useState("");

  const { data, error, status, refetch } = useQuery({
    queryKey: ["attendance-request"],
    queryFn: fetchAttendanceRequests,
    refetchOnWindowFocus: false,
  });
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };
  //===============================Update
  const { mutate: updateAttendenceRequestStatusMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateAttendenceRequestStatus,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Attendence status Updated", type: "Updated" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
        setToastType({
          label: error?.response?.data?.message || error?.message || "An Error Occurred While  Updating status",
          type: "Error",
        });
    },
  });

  //===============================edit
  const { mutate: editAttendenceRequestStatusMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: editAttendanceRequest,
    onSuccess: (res) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: res?.response || "Attendance Request Updated", type: "Updated" });
    },
    onError: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
        setToastType({
          label: error?.response?.data?.message || error?.message || "An Error Occurred While Updating Attendance Request",
          type: "Error",
        });
    },
  });

  const editAttReqData = (data) => {
    debugger
    editAttendenceRequestStatusMutation(data);
  };

  const { mutate: deleteAttendanceMutation } = useMutation({
    mutationFn: deleteAttendanceRequest,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Attendance Request Deleted", type: "Deleted" });
    },
    onError: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Deleting Leave",
        type: "Error",
      });
    },
  });

  const updateAttReqStatus = (item, status) => {
    const data = {
      request_id: item.id,
      status,
    };
    updateAttendenceRequestStatusMutation(data);
  };

  if (status === "error") {
    return (
      <div className="page-wrapper">
        <span className="content container-fluid">Error: {error.message}</span>
      </div>
    );
  }

  if (status === "loading") {
    return <Spinnerloaders />;
  }

  const leavesData = data || [];

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  const formatDate = (date) => {
    if (!date) return "N/A";
    return dayjs(date).format("DD-MM-YYYY");
  };

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      render: (text) => <span className="table-avatar">TXP-{text}</span>,
    },
    {
      title: "Employee Name",
      dataIndex: "employee",
      render: (employee) => {
        const firstName = employee?.firstname || "";
        const lastName = employee?.lastname || "";
        const fullName = `${firstName} ${lastName}`.trim() || "N/A"; // Trim to handle cases where both names are empty
        return <span className="table-avatar">{fullName}</span>;
      },
    },
    {
      title: "Punch Type",
      dataIndex: "type",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text) => <span>{formatDate(text)}</span>,
      sorter: (a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateB - dateA; // Ascending order
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: "Time",
      dataIndex: "time",
      render: (text) => <span>{text ? text : "N/A"}</span>,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      render: (text) => (
        <Popover
          content={<span>{text}</span>}
          title="Attendance Request Reason"
          trigger="hover"
          overlayStyle={{ maxWidth: "300px" }}
        >
          <span
            style={{
              display: "inline-block",
              maxWidth: "200px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {text}
          </span>
        </Popover>
      ),
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
      render: (text) => (
        <Popover
          content={<span>{text}</span>}
          title="Attendance Request Feedback"
          trigger="hover"
          overlayStyle={{ maxWidth: "300px" }}
        >
          <span
            style={{
              display: "inline-block",
              maxWidth: "200px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {text}
          </span>
        </Popover>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="dropdown action-label text-left">
          <Link
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i
              className={
                text === "Pending"
                  ? "far fa-dot-circle text-info"
                  : text === "Approved"
                  ? "far fa-dot-circle text-success"
                  : "far fa-dot-circle text-danger"
              }
            />{" "}
            {text}
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => updateAttReqStatus(record, "Pending")}
            >
              <i className="far fa-dot-circle text-info" /> Pending
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => updateAttReqStatus(record, "Approved")}
            >
              <i className="far fa-dot-circle text-success" /> Approved
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => updateAttReqStatus(record, "Decline")}
            >
              <i className="far fa-dot-circle text-danger" /> Decline
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_attendance_request_popup"
              onClick={() => {
                setSelectedId(record.id);
                setSelectedDate(record.date);
                setSelectedEmoId(record.employee_id);
                setSelectedReason(record.reason);
                setSelectedType(record?.type);
                setSelectedTime(record.time);
                setFeedback(record.feedback);
                setSelectedStatus(record?.status);
                setEditable(true);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => {
                setSelectedId(record?.id);
              }}
            >
              <i className="fa-regular fa-trash-can m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs maintitle="Attendance Request" />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns}
                  dataSource={leavesData}
                  style={{ overflowX: "auto" }}
                  className="table-striped"
                  rowKey={(record) => record.id}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
        {success && (
          <InfoToast
            success={success}
            handleClose={handleSolidPrimaryToastClose}
            toastType={toastType}
          />
        )}
      </div>
      <EditAttendanceRequestPopup
        editAttReqData={editAttReqData}
        selectedDate={selectedDate}
        selectedType={selectedType}
        selectedEmoId={selectedEmoId}
        selectedReason={selectedReason}
        selectedTime={selectedTime}
        selectedStatus={selectedStatus}
        selectedId={selectedId}
        feedback={feedback}
      />
      <DeleteModal
        id={selectedId}
        deleteDepartment={deleteAttendanceMutation}
        Name="Delete Leaves"
      />
    </>
  );
};

export default AttendenceRequest;
