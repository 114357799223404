import React, { useState, useEffect } from "react";
import Select from "react-select";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";

const EditAttendanceRequestPopup = ({
  editAttReqData,
  selectedDate,
  selectedType,
  selectedEmoId,
  selectedReason,
  selectedTime,
  selectedId,
  feedback,
  selectedStatus,
}) => {
  const [employeeID, setEmployeeID] = useState("");
  const [requestField, setRequestField] = useState("");
  const [requestDate, setRequestDate] = useState(null);
  const [requestTime, setRequestTime] = useState(null);
  const [leaveReason, setLeaveReason] = useState("");
  const [leaveFeedback, setLeaveFeedback] = useState("");
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [aRstatus, setARStatus] = useState("");

  console.log("REQUESTing", requestField, selectedType);
  useEffect(() => {
    setEmployeeID(selectedEmoId || "");
    setRequestField(selectedType || "");
    setRequestField(selectedStatus || "");
    setRequestDate(selectedDate ? dayjs(selectedDate) : null);
    setRequestTime(selectedTime ? dayjs(selectedTime, "h:mm A") : null);
    setLeaveReason(selectedReason || "");
    setARStatus(selectedStatus || "");
    setLeaveFeedback(feedback || "");
  }, [
    selectedEmoId,
    selectedType,
    selectedDate,
    selectedTime,
    selectedReason,
    selectedId,
    selectedStatus,
    feedback,
  ]);

  useEffect(() => {
    const isComplete =
      requestField &&
      requestDate &&
      leaveReason &&
      requestTime &&
      aRstatus &&
      leaveFeedback;
    setIsFormComplete(isComplete);
  }, [
    requestField,
    requestDate,
    requestTime,
    leaveReason,
    requestTime,
    leaveFeedback,
  ]);

  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Decline", label: "Decline" },
  ];

  const requestOptions = [
    { value: "Check In", label: "Check In" },
    { value: "Check Out", label: "Check Out" },
  ];

  const handleTimeChange = (time) => {
    // Set time directly as a dayjs object
    setRequestTime(time ? dayjs(time) : null);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      id: selectedId,
      employee_id: employeeID,
      type: selectedType,
      date: requestDate ? dayjs(requestDate).format("YYYY-MM-DD") : null,
      time: requestTime ? requestTime.format("h:mm A") : null, // Format time as "h:mm A"
      reason: leaveReason,
      status: aRstatus,
      feedback: leaveFeedback,
    };
    editAttReqData(formData);
  };

  return (
    <div
      id="edit_attendance_request_popup"
      className="modal custom-modal fade"
      role="dialog"
      tabIndex="-1"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Attendance Request</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label className="form-label">Employee ID</label>
                <input
                  className="form-control"
                  type="text"
                  value={`TXP-${employeeID || ""}`}
                  onChange={(e) =>
                    setEmployeeID(e.target.value.replace(/[^0-9]/g, ""))
                  } // Ensure numeric only
                  placeholder="Enter Employee ID"
                  readOnly
                />
              </div>

              <div className="mb-3">
                <label className="form-label">
                  Request Type <span className="text-danger">*</span>
                </label>
                <Select
                  options={requestOptions}
                  placeholder="Select Request Type"
                  onChange={(option) =>
                    setRequestField(option ? option.value : "")
                  }
                  value={
                    requestOptions.find(
                      (option) => option.value === selectedType
                    ) || null
                  }
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      maxWidth: "100%",
                    }),
                    control: (provided) => ({
                      ...provided,
                      minHeight: "36px",
                      height: "36px",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
              </div>
              <div className="mb-3">
                <label className="form-label">
                  Status <span className="text-danger">*</span>
                </label>
                <Select
                  options={statusOptions}
                  placeholder="Status"
                  onChange={(statusOption) =>
                    setARStatus(statusOption ? statusOption.value : "")
                  }
                  value={
                    statusOptions.find(
                      (option) => option.value === aRstatus
                    ) || null
                  }
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      maxWidth: "100%",
                    }),
                    control: (provided) => ({
                      ...provided,
                      minHeight: "36px",
                      height: "36px",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      zIndex: 9999,
                    }),
                  }}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">
                  Date <span className="text-danger">*</span>
                </label>
                <DatePicker
                  onChange={(date) =>
                    setRequestDate(date ? date.toDate() : null)
                  }
                  value={requestDate ? dayjs(requestDate) : null}
                  className="form-control datetimepicker cal-icon"
                  format="DD-MM-YYYY"
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">
                  Time <span className="text-danger">*</span>
                </label>
                <TimePicker
                  onChange={handleTimeChange}
                  value={requestTime}
                  format="h:mm A"
                  className="form-control datetimepicker"
                  getPopupContainer={(trigger) => trigger.parentElement}
                />
              </div>

              <div className="mb-3">
                <label className="form-label">
                  Reason <span className="text-danger">*</span>
                </label>
                <textarea
                  rows={2}
                  className="form-control"
                  value={leaveReason}
                  onChange={(e) => setLeaveReason(e.target.value)}
                  placeholder="Enter reason"
                />
              </div>

              <div className="mb-3">
                <label className="form-label">
                  Feedback <span className="text-danger">*</span>
                </label>
                <textarea
                  rows={2}
                  className="form-control"
                  value={leaveFeedback}
                  onChange={(e) => setLeaveFeedback(e.target.value)}
                  placeholder="Enter Feedback"
                />
              </div>

              <div className="text-center">
                <button
                  className="btn btn-primary"
                  type="submit"
                  data-bs-dismiss="modal"
                  disabled={!isFormComplete}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAttendanceRequestPopup;
