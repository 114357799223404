import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchAllEmployees } from "../../hooks/useEmployee";

const LeaveSettingEditPopup = ({
  leaveTypeId,
  updateItemId,
  policy,
  editPolicy,
}) => {


  const [optionsFrom, setOptionsFrom] = useState([]);
  const [selectedFrom, setSelectedFrom] = useState([]);
  const [selectedTo, setSelectedTo] = useState([]);
  const [optionsTo, setOptionsTo] = useState([]);
  const [formattedEmployees, setFormattedEmployees] = useState([]);

  const { data, status } = useQuery({
    queryKey: ["allEmployees"],
    queryFn: fetchAllEmployees,
  });

  useEffect(() => {
    if (status === "success" && data?.employees) {
      const formatted = data.employees.map((employee) => ({
        label: `${employee.firstname} ${employee.lastname}`,
        id: employee.id,
      }));
      setFormattedEmployees(formatted);

      if (policy) {
        const policyEmployeeIds = policy.employees.map((emp) => emp.id);

        setOptionsTo(
          formatted.filter((employee) =>
            policyEmployeeIds.includes(employee.id)
          )
        );
        setOptionsFrom(
          formatted.filter(
            (employee) => !policyEmployeeIds.includes(employee.id)
          )
        );
        setSelectedTo(
          formatted.filter((employee) =>
            policyEmployeeIds.includes(employee.id)
          )
        );
      } else {
        setOptionsFrom(formatted);
        setOptionsTo([]);
      }
    }
  }, [data, status, policy]);

  const moveRightAll = () => {
    setOptionsTo([...optionsTo, ...optionsFrom]);
    setOptionsFrom([]);
  };

  const moveRightSelected = () => {
    const selectedIds = selectedFrom.map((emp) => emp.id);
    setOptionsTo([
      ...optionsTo,
      ...optionsFrom.filter((option) => selectedIds.includes(option.id)),
    ]);
    setOptionsFrom(
      optionsFrom.filter((option) => !selectedIds.includes(option.id))
    );
    setSelectedFrom([]);
  };

  const moveLeftSelected = () => {
    const selectedIds = selectedTo.map((emp) => emp.id);
    setOptionsFrom([
      ...optionsFrom,
      ...optionsTo.filter((option) => selectedIds.includes(option.id)),
    ]);
    setOptionsTo(
      optionsTo.filter((option) => !selectedIds.includes(option.id))
    );
    setSelectedTo([]);
  };

  const moveLeftAll = () => {
    setOptionsFrom([...optionsFrom, ...optionsTo]);
    setOptionsTo([]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const policyName = e.target.policyName.value;
    const days = e.target.days.value;

    if (policyName && days && optionsTo.length > 0) {
      const updatedPolicy = {
        id: updateItemId,
        leave_type_id: leaveTypeId,
        policy_name: policyName,
        employee_id: optionsTo.map((employee) => employee.id),
        days: parseInt(days),
      };
    
      editPolicy(updatedPolicy);
    } else {
      console.log("Policy Name, Days or Employee ID is missing");
    }
  };

  return (
    <>
      <div
        id="edit_custom_policy"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Custom Policy</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Policy Name <span className="text-danger">*</span>
                  </label>
                  <input
                    name="policyName"
                    type="text"
                    className="form-control"
                    defaultValue={policy?.policy_name || ""}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Days <span className="text-danger">*</span>
                  </label>
                  <input
                    name="days"
                    type="text"
                    className="form-control"
                    defaultValue={policy?.days || ""}
                  />
                </div>
                <div className="input-block mb-3 leave-duallist">
                  <label className="col-form-label">Edit employee</label>
                  <div className="row">
                    <div className="col-lg-5 col-sm-5">
                      <select
                        multiple
                        size="5"
                        className="form-control form-select"
                        value={selectedFrom.map((item) => item.id)}
                        onChange={(e) =>
                          setSelectedFrom(
                            Array.from(e.target.selectedOptions, (option) =>
                              optionsFrom.find(
                                (emp) => emp.id === parseInt(option.value)
                              )
                            )
                          )
                        }
                      >
                        {optionsFrom.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="multiselect-controls col-lg-2 col-sm-2">
                      <button
                        type="button"
                        onClick={moveRightAll}
                        className="btn w-100 btn-white mb-2"
                      >
                        <i className="fa fa-forward" />
                      </button>
                      <button
                        type="button"
                        onClick={moveRightSelected}
                        className="btn w-100 btn-white mb-2"
                      >
                        <i className="fa fa-chevron-right" />
                      </button>
                      <button
                        type="button"
                        onClick={moveLeftSelected}
                        className="btn w-100 btn-white mb-2"
                      >
                        <i className="fa fa-chevron-left" />
                      </button>
                      <button
                        type="button"
                        onClick={moveLeftAll}
                        className="btn w-100 btn-white mb-2"
                      >
                        <i className="fa fa-backward" />
                      </button>
                    </div>
                    <div className="col-lg-5 col-sm-5">
                      <select
                        multiple
                        size="5"
                        className="form-control form-select"
                        value={selectedTo.map((item) => item.id)}
                        onChange={(e) =>
                          setSelectedTo(
                            Array.from(e.target.selectedOptions, (option) =>
                              optionsTo.find(
                                (emp) => emp.id === parseInt(option.value)
                              )
                            )
                          )
                        }
                      >
                        {optionsTo.map((option) => (
                          <option key={option.id} value={option.id}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveSettingEditPopup;
