import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { addExperinceInformation } from "../../hooks/useEmployeeMoreInfo";
import { useMutation } from "@tanstack/react-query";

const schema = yup.object().shape({
  experienceInfo: yup.array().of(
    yup.object().shape({
      companyName: yup.string().required("Company Name is required"),
      location: yup.string().required("Location is required"),
      jobPosition: yup.string().required("Job Position is required"),
      periodFrom: yup.date().nullable().required("Period From is required"),
      periodTo: yup.date().nullable().required("Period To is required"),
    })
  ),
});

const PersonalInfoExperience = ({ employee_experience, refetch }) => {
  const { mutate: addExperinceInformationMutation } = useMutation({
    mutationFn: addExperinceInformation,
    onSuccess: (data) => {
      reset();
      refetch();
    },
  });

  const addExperinceData = (data) => {
    addExperinceInformationMutation(data);
  };

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "experienceInfo",
  });

  useEffect(() => {
    const defaultValues = {
      experienceInfo:
        employee_experience?.length > 0
          ? employee_experience.map((item) => ({
              companyName: item.company_name || "",
              location: item.location || "",
              jobPosition: item.position || "",
              periodFrom: item.start_date ? dayjs(item.start_date) : null,
              periodTo: item.end_date ? dayjs(item.end_date) : null,
            }))
          : [
              {
                companyName: "",
                location: "",
                jobPosition: "",
                periodFrom: null,
                periodTo: null,
              },
            ],
    };

    reset(defaultValues);
  }, [employee_experience, reset]);

  const onSubmit = (data) => {
    const payload = data?.experienceInfo?.map((item) => ({
      employee_id: id,
      company_name: item.companyName,
      location: item.location,
      position: item.jobPosition,
      start_date: item.periodFrom
        ? dayjs(item.periodFrom).format("YYYY-MM-DD")
        : null,
      end_date: item.periodTo
        ? dayjs(item.periodTo).format("YYYY-MM-DD")
        : null,
    }));
    addExperinceData(payload);
  };

  return (
    <div id="experience_info" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Experience Information</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-scroll">
                {fields.map((field, index) => (
                  <div className="card mb-3" key={field.id}>
                    <div className="card-body">
                      <h3 className="card-title">
                        Experience Information
                        {index > 0 && (
                          <div
                            onClick={() => remove(index)}
                            className="delete-icon"
                            role="button"
                          >
                            <i className="fa-regular fa-trash-can" />
                          </div>
                        )}
                      </h3>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div
                            className={`input-block mb-3 form-focus focused ${
                              errors.experienceInfo?.[index]?.companyName
                                ? "has-error"
                                : ""
                            }`}
                          >
                            <Controller
                              name={`experienceInfo[${index}].companyName`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <input
                                    {...field}
                                    type="text"
                                    className="form-control floating"
                                  />
                                  <label className="focus-label">
                                    Company Name{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </>
                              )}
                            />
                            <p className="error-message text-danger mt-1">
                              {
                                errors.experienceInfo?.[index]?.companyName
                                  ?.message
                              }
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`input-block mb-3 form-focus focused ${
                              errors.experienceInfo?.[index]?.location
                                ? "has-error"
                                : ""
                            }`}
                          >
                            <Controller
                              name={`experienceInfo[${index}].location`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <input
                                    {...field}
                                    type="text"
                                    className="form-control floating"
                                  />
                                  <label className="focus-label">
                                    Location{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </>
                              )}
                            />
                            <p className="error-message text-danger mt-1">
                              {
                                errors.experienceInfo?.[index]?.location
                                  ?.message
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div
                            className={`input-block mb-3 form-focus focused ${
                              errors.experienceInfo?.[index]?.jobPosition
                                ? "has-error"
                                : ""
                            }`}
                          >
                            <Controller
                              name={`experienceInfo[${index}].jobPosition`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <input
                                    {...field}
                                    type="text"
                                    className="form-control floating"
                                  />
                                  <label className="focus-label">
                                    Job Position{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </>
                              )}
                            />
                            <p className="error-message text-danger mt-1">
                              {
                                errors.experienceInfo?.[index]?.jobPosition
                                  ?.message
                              }
                            </p>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`input-block mb-3 form-focus focused ${
                              errors.experienceInfo?.[index]?.periodFrom
                                ? "has-error"
                                : ""
                            }`}
                          >
                            <Controller
                              name={`experienceInfo[${index}].periodFrom`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <DatePicker
                                    {...field}
                                    value={
                                      field.value ? dayjs(field.value) : null
                                    }
                                    onChange={(date) =>
                                      field.onChange(
                                        date ? dayjs(date).toDate() : null
                                      )
                                    }
                                    className="form-control floating datetimepicker"
                                    format="DD-MM-YYYY"
                                  />
                                  <label className="focus-label">
                                    Period From{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </>
                              )}
                            />
                            <p className="error-message text-danger mt-1">
                              {
                                errors.experienceInfo?.[index]?.periodFrom
                                  ?.message
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <div
                            className={`input-block mb-3 form-focus focused ${
                              errors.experienceInfo?.[index]?.periodTo
                                ? "has-error"
                                : ""
                            }`}
                          >
                            <Controller
                              name={`experienceInfo[${index}].periodTo`}
                              control={control}
                              render={({ field }) => (
                                <>
                                  <DatePicker
                                    {...field}
                                    value={
                                      field.value ? dayjs(field.value) : null
                                    }
                                    onChange={(date) =>
                                      field.onChange(
                                        date ? dayjs(date).toDate() : null
                                      )
                                    }
                                    className="form-control floating datetimepicker"
                                    format="DD-MM-YYYY"
                                  />
                                  <label className="focus-label">
                                    Period To{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                </>
                              )}
                            />
                            <p className="error-message text-danger mt-1">
                              {
                                errors.experienceInfo?.[index]?.periodTo
                                  ?.message
                              }
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className="add-more"
                  style={{ color: "#FF902F" }}
                  role="button"
                >
                  <div
                    onClick={() =>
                      append({
                        companyName: "",
                        location: "",
                        jobPosition: "",
                        periodFrom: null,
                        periodTo: null,
                      })
                    }
                  >
                    <i className="fa-solid fa-plus-circle" /> Add More
                  </div>
                </div>
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="submit"
                  disabled={!isValid}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoExperience;
