import React, { useState, useEffect } from "react";
import Select from "react-select";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import { useMutation } from "@tanstack/react-query";
import { addAttendanceRequest } from "../../hooks/useAttendence";
import { headerlogoblack } from "../../Routes/ImagePath";
import { Link } from "react-router-dom";
import InfoToast from "../../views/layout/InfoToast";

const AttendanceRequestForm = () => {
  const [employeeID, setEmployeeID] = useState("");
  const [requestField, setRequestField] = useState("");
  const [requestDate, setRequestDate] = useState(null);
  const [requestTime, setRequestTime] = useState(null);
  const [leaveReason, setLeaveReason] = useState("");
  const [isFormComplete, setIsFormComplete] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  const { mutate: addEmployeeMutationAttendanceRequest } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: addAttendanceRequest,
    onSuccess: () => {
      setIsRefetching(false);
      setSuccess(true);
      setToastType({ label: "Attendance Request Added", type: "Added" });
    },
    onError: (error) => {
      setIsRefetching(false);
      setSuccess(true);
      setToastType({
        label: error?.response?.data?.message || error.response?.message || "Attendance Request Error",
        type: "Error",
      });
    },
  });

  const addEmployeeAttendanceRequest = (data) => {
    addEmployeeMutationAttendanceRequest(data);
  };

  useEffect(() => {
    const isComplete = employeeID && requestField && requestDate && leaveReason;
    setIsFormComplete(isComplete);
  }, [employeeID, requestField, requestDate, requestTime, leaveReason]);

  const requestOptions = [
    { value: "Check In", label: "Check In" },
    { value: "Check Out", label: "Check Out" },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = {
      employee_id: employeeID.trim(),
      type: requestField,
      date: requestDate ? dayjs(requestDate).format("YYYY-MM-DD") : null,
      time: requestTime,
      reason: leaveReason.trim(),
    };
    addEmployeeAttendanceRequest(formData);
    setEmployeeID("");
    setRequestField("");
    setRequestDate(null);
    setRequestTime(null);
    setLeaveReason("");
  };

  const handleEmployeeIDChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, ""); // Allow only numeric characters
    setEmployeeID(value);
  };
  const containerStyle = {
    margin: "0 auto",
    width: "600px",
    padding: "30px 20px",
    textAlign: "center",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#fff",
  };

  const logoContainerStyle = {
    display: "flex",
    justifyContent: "center",
  };

  const logoStyle = {
    maxWidth: "140px",
    height: "auto",
    marginBottom: "10px",
    marginTop: "0px",
  };

  const formControlStyle = {
    maxWidth: "450px",
    width: "100%",
    boxSizing: "border-box",
    margin: "0 auto 20px auto",
    textAlign: "left",
    marginTop: "20px",
  };

  // Function to disable future dates
  const disableFutureDates = (current) => {
    return current && current.isAfter(dayjs().endOf("day"));
  };

  return (
    <>
      <div
        style={{
          margin: "40px 0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div style={containerStyle}>
          <div style={logoContainerStyle}>
            <Link to="/">
              <img src={headerlogoblack} alt="XpertPrime" style={logoStyle} />
            </Link>
          </div>
          <h1>Attendance Request</h1>
          <form onSubmit={handleSubmit}>
            <div style={formControlStyle}>
              <label className="col-form-label">
                Employee ID <span className="text-danger">*</span> (e.g TXP - 0## or TXP - 0#)
              </label>
              <input
                className="form-control"
                type="text"
                value={`TXP - ${employeeID || ""}`}
                onChange={handleEmployeeIDChange}
                placeholder="Enter Employee ID"
              />
            </div>

            <div style={formControlStyle}>
              <label className="col-form-label">
                Request Type <span className="text-danger">*</span>
              </label>
              <Select
                options={requestOptions}
                placeholder="Select Request Type"
                onChange={(selectedOption) =>
                  setRequestField(selectedOption ? selectedOption.value : "")
                }
                value={
                  requestOptions.find(
                    (option) => option.value === requestField
                  ) || null
                }
                styles={{
                  container: (provided) => ({
                    ...provided,
                    maxWidth: "100%",
                  }),
                  control: (provided) => ({
                    ...provided,
                    minHeight: "36px",
                    height: "36px",
                  }),
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
              />
            </div>

            <div style={formControlStyle}>
              <label className="col-form-label">
                Date <span className="text-danger">*</span>
              </label>
              <DatePicker
                onChange={(date) => setRequestDate(date ? date.toDate() : null)}
                value={requestDate ? dayjs(requestDate) : null}
                className="form-control datetimepicker cal-icon"
                format="DD-MM-YYYY"
                getPopupContainer={(trigger) => trigger.parentElement}
                disabledDate={disableFutureDates}
              />
            </div>

            <div style={formControlStyle}>
              <label className="col-form-label">Time</label>
              <TimePicker
                onChange={(time) =>
                  setRequestTime(time ? dayjs(time).format("h:mm A") : null)
                }
                value={requestTime ? dayjs(requestTime, "h:mm A") : null}
                format="h:mm A"
                className="form-control datetimepicker cal-icon"
                getPopupContainer={(trigger) => trigger.parentElement}
              />
            </div>

            <div style={formControlStyle}>
              <label className="col-form-label">
                Reason <span className="text-danger">*</span>
              </label>
              <textarea
                rows={4}
                className="form-control"
                value={leaveReason}
                onChange={(e) => setLeaveReason(e.target.value)}
                style={{ width: "100%", boxSizing: "border-box" }}
                placeholder="Enter reason"
              />
            </div>

            <div style={{ textAlign: "center" }}>
              <button
                className="btn btn-primary submit-btn"
                type="submit"
                disabled={!isFormComplete}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </>
  );
};

export default AttendanceRequestForm;
