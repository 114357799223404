import React, { useEffect, useState } from "react";
import { Avatar_02 } from "../../Routes/ImagePath";
import { Link, useLocation } from "react-router-dom";
import { DatePicker } from "antd";
import Select from "react-select";
import PersonalInfoEducation from "./PersonalInfoEducation";
import PersonalInfoExperience from "./PersonalInfoExperience";
import PersonalInfoBankInfo from "./PersonalInfoBankInfo";
import {
  addEmergencyContactInformation,
  addPersonalInformation,
} from "../../hooks/useEmployeeMoreInfo";
import { useMutation } from "@tanstack/react-query";
import InfoToast from "../../views/layout/InfoToast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useForm, Controller } from "react-hook-form";

// Validation schema using Yup
const personalInfoSchema = Yup.object({
  cnic: Yup.string()
    .matches(
      /^\d{5}-\d{7}-\d{1}$/,
      "CNIC must be in the format XXXXX-XXXXXXX-X"
    )
    .required("CNIC is required"),
  nationality: Yup.string().required("Nationality is required"),
  martial_status: Yup.string().required("Marital Status is required"),
  no_of_dependents: Yup.string(), // Mark as optional
  father_husband_name: Yup.string().notRequired(), // Mark as optional
  father_occupation: Yup.string().notRequired(), // Mark as optional
  blood_group: Yup.string().notRequired(), // Mark as optional
  disability: Yup.string().notRequired(), // Mark as optional
});

const PersonalInformationModelPopup = ({
  data,
  employee_contact = [],
  employee_bank,
  employee_education,
  employee_experience,
  refetch,
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [selectedDate1, setSelectedDate1] = useState(null);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  // Initialize react-hook-form
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    watch,
    formState: { errors }, // Include isDirty to track if form is interacted with
  } = useForm({
    resolver: yupResolver(personalInfoSchema),
    mode: "all", // Ensures form validation is triggered on every change
    defaultValues: {
      id: id,
      cnic: "",
      nationality: "",
      martial_status: "",
      no_of_dependents: "",
      father_husband_name: "",
      blood_group: "",
      father_occupation: "",
      disability: "",
    },
  });

  // Reset form values when `data` changes
  useEffect(() => {
    if (data) {
      reset({
        id: id,
        cnic: data?.cnic || "",
        nationality: data?.nationality || "",
        martial_status: data?.martial_status || "",
        no_of_dependents: data?.no_of_dependents || "",
        father_husband_name: data?.father_husband_name || "",
        blood_group: data?.blood_group || "",
        father_occupation: data?.father_occupation || "",
        disability: data?.disability || "",
      });
    }
  }, [data, id, reset]);

  const statusOptions = [
    { value: "Single", label: "Single" },
    { value: "Married", label: "Married" },
    { value: "Widow", label: "Widow" },
    { value: "Separated", label: "Separated" },
    { value: "Divorced", label: "Divorced" },
  ];
  const bloodGroups = [
    { value: "A+", label: "A+" },
    { value: "B+", label: "B+" },
    { value: "AB+", label: "AB+" },
    { value: "O+", label: "O+" },
    { value: "A-", label: "A-" },
    { value: "B-", label: "B-" },
    { value: "AB-", label: "AB-" },
    { value: "O-", label: "O-" },
  ];
  const DisabilityOptions = [
    { value: "No", label: "No" },
    { value: "Yes", label: "Yes" },
  ];

  const handleSelectChange = (selectedOption, name) => {
    setValue(name, selectedOption ? selectedOption.value : "");
  };

  const handleFormSubmit = (data) => {
    addEmployeePersonalData(data);
  };

  const { mutate: addPersonalInformationMutation } = useMutation({
    mutationFn: addPersonalInformation,
    onSuccess: () => {
      refetch();
      setSuccess(true);
      setToastType({ label: "Personal Information Updated", type: "Updated" });
    },
  });

  const handleInputChange = (e) => {
    let value = e.target.value;

    value = value.replace(/[^0-9-]/g, "");

    if (value.split("-").length > 2) {
      value = value.replace(/-+/g, "-");
    }

    if (value.startsWith("-")) {
      value = "-" + value.replace(/^-/, "");
    }

    e.target.value = value; // Update the input field
  };

  const cnic = watch("cnic");
  const nationality = watch("nationality");
  const martial_status = watch("martial_status");

  const isValid =
    cnic.trim() !== "" &&
    nationality.trim() !== "" &&
    martial_status.trim() !== "";

  const addEmployeePersonalData = (data) => {
    addPersonalInformationMutation(data);
  };

  // ===========================Add Emergency Contact======================

  const [primaryContact, setPrimaryContact] = useState({
    employee_id: id,
    name: "",
    type: "primary",
    relation: "",
    phone: "",
  });

  const [secondaryContact, setSecondaryContact] = useState({
    employee_id: id,
    name: "",
    type: "secondary",
    relation: "",
    phone: "",
  });

  // Update contact states when employee_contact changes
  useEffect(() => {
    if (employee_contact.length > 0) {
      setPrimaryContact({
        employee_id: id,
        name: employee_contact[0].name || "",
        type: "primary",
        relation: employee_contact[0].relation || "",
        phone: employee_contact[0].phone || "",
      });
    }
    if (employee_contact.length > 1) {
      setSecondaryContact({
        employee_id: id,
        name: employee_contact[1].name || "",
        type: "secondary",
        relation: employee_contact[1].relation || "",
        phone: employee_contact[1].phone || "",
      });
    }
  }, [employee_contact, id]);

  const areContactsValid = () => {
    const isPrimaryValid =
      primaryContact.name.trim() !== "" &&
      primaryContact.phone.trim() !== "" &&
      primaryContact.relation.trim() !== "";

    const isSecondaryValid =
      secondaryContact.name.trim() !== "" &&
      secondaryContact.phone.trim() !== "" &&
      secondaryContact.relation.trim() !== "";

    return isPrimaryValid && isSecondaryValid;
  };

  const handlePrimaryChange = (e) => {
    const { name, value } = e.target;
    setPrimaryContact({ ...primaryContact, [name]: value });
  };

  const handleSecondaryChange = (e) => {
    const { name, value } = e.target;
    setSecondaryContact({ ...secondaryContact, [name]: value });
  };

  const handleContactSubmit = (e) => {
    e.preventDefault();
    const payload = [{ ...primaryContact }, { ...secondaryContact }];
    addEmergencyContactData(payload);
  };
  const { mutate: addEmergencyContactInformationMutation } = useMutation({
    mutationFn: addEmergencyContactInformation,
    onSuccess: () => {
      refetch();
      setSuccess(true);
      setToastType({ label: "Emergency Contact Updated", type: "Updated" });
    },
  });

  const addEmergencyContactData = (data) => {
    addEmergencyContactInformationMutation(data);
  };
  // ===========================Add Emergency Contact End======================

  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
  };
  const domain = [
    { value: 1, label: "Select Department" },
    { value: 2, label: "Web Development+" },
    { value: 3, label: "IT Management" },
    { value: 4, label: "Marketing" },
  ];
  const developer = [
    { value: 1, label: "Select Department" },
    { value: 2, label: "Web Development+" },
    { value: 3, label: "IT Management" },
    { value: 4, label: "Marketing" },
  ];
  const reporter = [
    { value: 2, label: "Wilmer Deluna" },
    { value: 3, label: "Lesley Grauer" },
    { value: 4, label: "Jeffery Lalor" },
  ];

  const gender = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  return (
    <>
      {/* profile_info Modal */}
      <div id="profile_info" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Profile Information</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-img-wrap edit-img">
                      <img
                        className="inline-block"
                        src={Avatar_02}
                        alt="user"
                      />
                      <div className="fileupload btn">
                        <span className="btn-text">edit</span>
                        <input className="upload" type="file" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">First Name</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="John"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Last Name</label>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue="Doe"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Birth Date</label>
                          <div>
                            <DatePicker
                              selected={selectedDate1}
                              onChange={handleDateChange1}
                              className="form-control floating datetimepicker"
                              type="date"
                              placeholderText="04/10/2023"
                              dateFormat="dd-MM-yyyy"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Gender</label>
                          <Select
                            options={gender}
                            placeholder="-"
                            styles={customStyles}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Address</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="4487 Snowbird Lane"
                      />
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Permanent Address
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="4487 Snowbird Lane"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">State</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="New York"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Country</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="United States"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Pin Code</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue={10523}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        defaultValue="631-889-3206"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Department <span className="text-danger">*</span>
                      </label>
                      <Select
                        options={domain}
                        placeholder="Select Department"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Designation <span className="text-danger">*</span>
                      </label>
                      <Select
                        options={developer}
                        placeholder="Select Department"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Reports To <span className="text-danger">*</span>
                      </label>

                      <Select
                        options={reporter}
                        placeholder="-"
                        styles={customStyles}
                      />
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="reset"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /profile_info Modal */}

      {/* Personal Info Modal */}
      <div
        id="personal_info_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Personal Information</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        CNIC No <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="cnic"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text" // Keep as text for manual validation
                            {...field}
                            onChange={(e) => {
                              handleInputChange(e);
                              field.onChange(e); // Ensure react-hook-form receives the updated value
                            }}
                            className="form-control"
                            placeholder="XXXXX-XXXXXXX-X"
                          />
                        )}
                      />
                      {errors.cnic && (
                        <div className="text-danger">{errors.cnic.message}</div>
                      )}
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Nationality <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="nationality"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="form-control"
                          />
                        )}
                      />
                      {errors.nationality && (
                        <div className="text-danger">
                          {errors.nationality.message}
                        </div>
                      )}
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Marital Status <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="martial_status"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={statusOptions}
                            placeholder="-"
                            styles={customStyles}
                            onChange={(option) =>
                              handleSelectChange(option, "martial_status")
                            }
                            value={statusOptions.find(
                              (option) => option.value === field.value
                            )}
                          />
                        )}
                      />
                      {errors.martial_status && (
                        <div className="text-danger">
                          {errors.martial_status.message}
                        </div>
                      )}
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Father/Husband Name
                      </label>
                      <Controller
                        name="father_husband_name"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="form-control"
                          />
                        )}
                      />
                      {errors.father_husband_name && (
                        <div className="text-danger">
                          {errors.father_husband_name.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Father Occupation:
                      </label>
                      <Controller
                        name="father_occupation"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="form-control"
                          />
                        )}
                      />
                      {errors.father_occupation && (
                        <div className="text-danger">
                          {errors.father_occupation.message}
                        </div>
                      )}
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Number Of Dependents:
                      </label>
                      <Controller
                        name="no_of_dependents"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            {...field}
                            className="form-control"
                          />
                        )}
                      />
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">Blood Group</label>
                      <Controller
                        name="blood_group"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={bloodGroups}
                            placeholder="-"
                            styles={customStyles}
                            onChange={(option) =>
                              handleSelectChange(option, "blood_group")
                            }
                            value={bloodGroups.find(
                              (option) => option.value === field.value
                            )}
                          />
                        )}
                      />
                      {errors.blood_group && (
                        <div className="text-danger">
                          {errors.blood_group.message}
                        </div>
                      )}
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">Any Disability</label>
                      <Controller
                        name="disability"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={DisabilityOptions}
                            placeholder="-"
                            styles={customStyles}
                            onChange={(option) =>
                              handleSelectChange(option, "disability")
                            }
                            value={DisabilityOptions.find(
                              (option) => option.value === field.value
                            )}
                          />
                        )}
                      />
                      {errors.disability && (
                        <div className="text-danger">
                          {errors.disability.message}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="submit"
                    disabled={!isValid}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Personal Info Modal */}

      {/*/Emergency Model*/}
      <div
        id="emergency_contact_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Emergency Contact Information</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleContactSubmit}>
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title">Primary Contact</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={primaryContact.name}
                            onChange={handlePrimaryChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Relationship <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="relation"
                            className="form-control"
                            value={primaryContact.relation}
                            onChange={handlePrimaryChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="phone"
                            className="form-control"
                            value={primaryContact.phone}
                            onChange={handlePrimaryChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <h3 className="card-title">Secondary Contact</h3>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            value={secondaryContact.name}
                            onChange={handleSecondaryChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Relationship <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="relation"
                            className="form-control"
                            value={secondaryContact.relation}
                            onChange={handleSecondaryChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            name="phone"
                            className="form-control"
                            value={secondaryContact.phone}
                            onChange={handleSecondaryChange}
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    type="submit"
                    disabled={!areContactsValid()}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Emergency Model End */}

      {/* Bank Information Modal */}
      <PersonalInfoBankInfo employee_bank={employee_bank} refetch={refetch} />
      {/* /Bank Information Modal */}

      {/* Education Modal */}
      <PersonalInfoEducation
        employee_education={employee_education}
        refetch={refetch}
      />
      {/* /Education Modal */}

      {/* Experience Modal */}
      <PersonalInfoExperience
        employee_experience={employee_experience}
        refetch={refetch}
      />
      {/* /Experience Modal */}

      <InfoToast
        success={success}
        handleClose={handleSolidPrimaryToastClose}
        toastType={toastType}
      />
    </>
  );
};

export default PersonalInformationModelPopup;
