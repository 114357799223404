import React, { useState } from "react";
import TableAvatar from "./TableAvatar";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useQuery } from "@tanstack/react-query";
import { fetchMonthlyAttendance } from "../../../hooks/useAttendence";

const AttendenceAdmin = () => {
  const [initialFilters, setInitialFilters] = useState({
    employee_name: "",
    year: "",
    month: "",
  });
  const { data, error, status, refetch } = useQuery({
    queryKey: ["monthly-attendance", initialFilters],
    queryFn: fetchMonthlyAttendance,
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Attendance"
            title="Dashboard"
            subtitle="Attendance"
            initialFilters={initialFilters}
          />
          {/* /Page Header */}

          <div className="row">
            <div className="col-lg-12">
              <div className="table-responsive" style={{overflowX:"hidden"}}>
                <TableAvatar
                  data={data}
                  setInitialFilters={setInitialFilters}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendenceAdmin;
