import { setAuthenticationToken } from "./auth";
import { axiosInstance } from "./axios";
// import { useQuery } from 'react-query'
export const fetchEmployeeAttendance = async () => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.get(`/employees/attendance`);
  return data?.data;
};
export const fetchMonthlyAttendance = async ({ queryKey }) => {
  const [, filters] = queryKey;
  setAuthenticationToken(localStorage.getItem("token"));
  const { month, year, employee_name } = filters;
  const params = new URLSearchParams();
  if (month) params.append("month", month);
  if (year) params.append("year", year);
  if (employee_name) params.append("employee_name", employee_name);

  const queryString = params.toString();

  // Fetch the attendance listing with the query parameters
  const { data } = await axiosInstance.get(`attendance-listing?${queryString}`);
  return data?.data;
};

export const addEmployeeAttendancePost = async (payload) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.post(`/employees/attendance`, payload);
  return data?.data;
};

export const updateEmployeeAttendance = async (res) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.put(
    `/employees/attendance?id=${res.id}&checkin=${res.checkin}&checkout=${res.checkout}&employee=${res.employee}`
  );
  return data?.data;
};

export const fetchSearchEmployeeAttendance = async (filters) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { month, year, employee_name } = filters;

  const params = new URLSearchParams();
  if (month) params.append("month", month);
  if (year) params.append("year", year);
  if (employee_name) params.append("employee_name", employee_name);
  const queryString = params.toString();
  const { data } = await axiosInstance.get(
    `/employees/attendance?${queryString}`
  );

  return data?.data;
};

export const addAttendanceRequest = async (payload) => {
  const { data } = await axiosInstance.post(
    `/employees/attendance-request`,
    payload
  );
  return data?.data;
};

// Attendance checkIn requests

export const fetchAttendanceRequests = async () => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.get(`/employees/attendance-request`);
  return data?.data;
};

export const updateAttendenceRequestStatus = async (res) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.post(
    `employees/attendance-request-status`,
    res
  );
  return data?.data;
};
export const deleteAttendanceRequest = async (id) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.delete(
    `employees/attendance-request?id=${id}}`
  );
  return data?.data;
};
export const editAttendanceRequest = async (payload) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.put(
    `employees/attendance-request?employee_id=${payload.employee_id}&date=${payload.date}&id=${payload.id}&time=${payload.time}&type=${payload.type}&reason=${payload.reason}&feedback=${payload.feedback}&status=${payload.status}`
  );
  return data?.data;
};
