import React, { useState, useEffect } from "react";
import { formatDate } from "../../assets/scss/utils/helpers/helper_functions";

const AttendenceModelPopup = ({ data }) => {
  const [punchHours, setPunchHours] = useState(null);
  const [overtimeHours, setOvertimeHours] = useState(null);

  const convertTo12HourFormat = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const adjustedHours = hours % 12 || 12; // Convert '0' to '12'
    return `${adjustedHours}:${minutes < 10 ? '0' : ''}${minutes} ${period}`;
  };

  useEffect(() => {
    if (data?.attendance?.checkIn && data?.attendance?.checkOut) {
      const [checkInHours, checkInMinutes] = data.attendance.checkIn.split(":").map(Number);
      const [checkOutHours, checkOutMinutes] = data.attendance.checkOut.split(":").map(Number);

      const checkInDate = new Date();
      checkInDate.setHours(checkInHours);
      checkInDate.setMinutes(checkInMinutes);

      const checkOutDate = new Date();
      checkOutDate.setHours(checkOutHours);
      checkOutDate.setMinutes(checkOutMinutes);

      // If check-out time is earlier than check-in time, assume check-out is on the next day
      if (checkOutDate <= checkInDate) {
        checkOutDate.setDate(checkOutDate.getDate() + 1);
      }

      const diffMs = checkOutDate - checkInDate;
      const diffHrs = Math.floor(diffMs / (1000 * 60 * 60));
      const diffMins = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
      setPunchHours(isNaN(diffMins) || isNaN(diffHrs) ? "N/A" : `${diffHrs}:${diffMins < 10 ? '0' : ''}${diffMins}`);

      // Calculate overtime if punch hours exceed 9 hours
      if (diffHrs > 9 || (diffHrs === 9 && diffMins > 0)) {
        const overtimeHrs = diffHrs - 9;
        const overtimeMins = diffMins;
        setOvertimeHours(`${overtimeHrs}:${overtimeMins < 10 ? '0' : ''}${overtimeMins}`);
      } else {
        setOvertimeHours("0:00");
      }
    } else {
      setPunchHours(null);
      setOvertimeHours(null);
    }
  }, [data]);

  return (
    <>
      <div className="modal custom-modal fade" id="attendance_info" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Attendance Info</h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="card punch-status">
                    <div className="card-body">
                      <h5 className="card-title">
                        Timesheet{" "}
                        <small className="text-muted">{formatDate(data.attendance?.date)}</small>
                      </h5>
                      <div className="punch-det">
                        <h6>Punch In at</h6>
                        <p>{data.attendance?.checkIn ? convertTo12HourFormat(data.attendance.checkIn) : "Check is Missing"}</p>
                      </div>
                      <div className="punch-info">
                        <div className="punch-hours">
                          <span>{punchHours || "N/A"}</span>
                        </div>
                      </div>
                      <div className="punch-det">
                        <h6>Punch Out at</h6>
                        <p>{data.attendance?.checkOut ? convertTo12HourFormat(data.attendance.checkOut) : "Checkout Is Missing"}</p>
                      </div>
                      <div className="statistics">
                        <div className="row">
                          <div className="col-md-12 col-12 text-center">
                            <div className="stats-box">
                              <p>Overtime</p>
                              <h6>{overtimeHours || "N/A"}</h6>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card recent-activity">
                    <div className="card-body">
                      <h5 className="card-title">Activity</h5>
                      <ul className="res-activity-list">
                        <li>
                          <p className="mb-0">Punch In at</p>
                          <p className="res-activity-time">
                            <i className="fa fa-clock-o" />
                            {data.attendance?.checkIn ? convertTo12HourFormat(data.attendance.checkIn) : "Checkin Is Missing"}
                          </p>
                        </li>
                        <li>
                          <p className="mb-0">Punch Out at</p>
                          <p className="res-activity-time">
                            <i className="fa fa-clock-o" />
                            {data.attendance?.checkOut ? convertTo12HourFormat(data.attendance.checkOut) : "Checkout Is Missing"}
                          </p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendenceModelPopup;
