import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap CSS is loaded

// Define validation schema with Yup
const schema = yup.object().shape({
  shiftName: yup.string().required("Shift name is required"),
  shiftStart: yup.string().required("Shift start time is required"),
  shiftEnd: yup.string().required("Shift end time is required"),
});

const AddShiftModal = ({ addShift }) => {
  // Initialize react-hook-form with schema validation
  const { handleSubmit, control, formState: { errors } } = useForm({
    resolver: yupResolver(schema),
  });

  // Handler function for form submission
  const onSubmitHandler = (data) => {
    const {shift_name, shift_start, shift_end} = {...data}
    addShift({shift_name, shift_start, shift_end});
  };

  return (
    <>
      <div
        id="addShiftModal"
        className="modal custom-modal fade"
        role="dialog"
        tabIndex="-1"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Shift</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Shift Name <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="shiftName"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${errors.shiftName ? "is-invalid" : ""}`}
                        type="text"
                      />
                    )}
                  />
                  {errors.shiftName && (
                    <div className="invalid-feedback">
                      {errors.shiftName.message}
                    </div>
                  )}
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Shift Start <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="shiftStart"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${errors.shiftStart ? "is-invalid" : ""}`}
                        type="time"
                      />
                    )}
                  />
                  {errors.shiftStart && (
                    <div className="invalid-feedback">
                      {errors.shiftStart.message}
                    </div>
                  )}
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Shift End <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="shiftEnd"
                    control={control}
                    render={({ field }) => (
                      <input
                        {...field}
                        className={`form-control ${errors.shiftEnd ? "is-invalid" : ""}`}
                        type="time"
                      />
                    )}
                  />
                  {errors.shiftEnd && (
                    <div className="invalid-feedback">
                      {errors.shiftEnd.message}
                    </div>
                  )}
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    data-bs-dismiss="modal"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddShiftModal;
