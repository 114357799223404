import React from "react";
import { PulseLoader } from "react-spinners";

const Spinnerloader = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "10px", marginBottom: "10px" }}>
      <PulseLoader color="#FF902F" />
    </div>
  );
};

export default Spinnerloader;