import { setAuthenticationToken } from './auth'
import { axiosInstance } from './axios'
// import { useQuery } from 'react-query'
export const addDepartmentPost = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`departments`, payload)   
   return data?.data;
}
export const fetchDepartments = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`departments`);
    return data?.data;
}
export const delDepartment = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`departments?id=${id}`)
    return data?.data;
}
export const updDepartment = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`departments?id=${res.id}&name=${res.name}`)
    return data?.data;
}