import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

const roleSchema = Yup.object().shape({
  name: Yup.string()
    .required('Role Name is required')
});
const RolesPermissionsModal = ({addRole, updateRole, selectedRole}) => {
  const [roleName, setRoleName] = useState('');

  useEffect(() => {
   setRoleName(selectedRole.name);
  }, [selectedRole])
  
  
  const handleEditSubmit = (e) => {
    e.preventDefault();
    updateRole({name:roleName, id:selectedRole.id})
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(roleSchema),
  });

  const onSubmit = (data) => {
    addRole(data)
    reset();
  };
  const onEditSubmit = (data) => {
    updateRole(data)
    reset();
  };

  return (
    <>
      {/* Add Role Modal */}
      <div id="add_role" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Role</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Role Name <span className="text-danger">*</span>
                </label>
                <input
                  className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                  type="text"
                  {...register('name')}
                />
                {errors.name && (
                  <div className="invalid-feedback">{errors.name.message}</div>
                )}
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
      {/* /Add Role Modal */}
      {/* Edit Role Modal */}
      <div id="edit_role" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content modal-md">
          <div className="modal-header">
            <h5 className="modal-title">Edit Role</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleEditSubmit}>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Role Name <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  value={roleName}
                  onChange={(e) => setRoleName(e.target.value)}
                  type="text"
                />
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
      {/* /Edit Role Modal */}
    </>
  );
};

export default RolesPermissionsModal;
