import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'


export const fetchUsers = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`users`)
    return data.data;
}
export const createUser = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`users`, payload)
    return data?.data;
}
export const updateUser = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`users?id=${res.id}`,res)
    return data?.data;
}
export const deleteUser = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`users?id=${id}`)
    return data?.data;
}