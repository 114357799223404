import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

const UserFilter = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedOptionTwo, setSelectedOptionTwo] = useState(null);
  const [nameValue, setNameValue] = useState(""); // Track the value of the input
  const [itemFocus, setItemFocus] = useState(false);

  const inputFocus = () => {
    // Set focus to true when the input is focused
    setItemFocus(true);
  };

  const inputBlur = () => {
    // Set focus to false only if the input field is empty
    if (!nameValue.trim()) {
      setItemFocus(false);
    }
  };

  const handleNameChange = (event) => {
    // Update the value of the input and manage the focus state
    setNameValue(event.target.value);
    if (event.target.value.trim()) {
      setItemFocus(true); // Keep focus if there's a value
    } else {
      setItemFocus(false); // Remove focus if the value is cleared
    }
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };


  const optionsTwo = [
    { value: 1, label: "Select Role" },
    { value: 2, label: "Web Developer" },
    { value: 3, label: "Web Designer" },
    { value: 4, label: "Android Developer" },
    { value: 5, label: "iOS Developer" },
  ];

  return (
    <div className="row filter-row space">
      <div className="col-sm-6 col-md-3">
        <div
          className={`input-block mb-3 form-focus ${itemFocus ? "focused" : ""}`}
        >
          <input
            type="text"
            className="form-control floating"
            value={nameValue}
            onChange={handleNameChange}
            onFocus={inputFocus}
            onBlur={inputBlur}
          />
          <label className="focus-label">Name</label>
        </div>
      </div>
      <div className="col-sm-6 col-md-3">
        <div className="input-block form-focus select-focus">
          <Select
            placeholder="Select Role"
            onChange={setSelectedOptionTwo}
            options={optionsTwo}
            className="select floating"
            styles={customStyles}
          />
          <label className="focus-label">Role</label>
        </div>
      </div>

      <div className="col-sm-6 col-md-3">
        <Link to="#" className="btn btn-success btn-block w-100">
          Search
        </Link>
      </div>
    </div>
  );
};

export default UserFilter;
