import { setAuthenticationToken } from './auth'
import { axiosInstance } from './axios'


export const fetchLeaveTypes = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`leave-type`);
    return data?.data;
}

export const addLeaveType = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`leave-type`, payload)   
   return data?.data;
}

export const updateLeaveType = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`leave-type?id=${res.id}&type=${res.type}&days=${res.days}&is_active=${res.is_active}`)
    return data?.data;
}

export const deleteLeaveType = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`leave-type?id=${id}`)
    return data?.data;
}