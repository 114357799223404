import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { checkMail } from "../../../hooks/useAuth";
import { useMutation } from "@tanstack/react-query";

const ForgotPassword = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialEmail = queryParams.get('email');
  const navigate = useNavigate();

  // Extract email from state or initialize empty
  // const initialEmail = location.state?.email || "";
  const [email, setEmail] = useState(initialEmail);
  const [error, setError] = useState("");

  // Mutation for checking mail
  const { mutate: mailCheckMutation, isLoading } = useMutation({
    mutationFn: checkMail,
    onSuccess: () => {
      navigate("/otp", { state: { email } });
    },
    onError: (error) => {
      setError(error?.message || "An error occurred");
    },
  });

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!email) {
      setError("Email is required");
      return;
    }
    mailCheckMutation({ email });
  };

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            <div className="account-logo">
              <Link to="/app/main/dashboard">
                <img src={Applogo} alt="Dreamguy's Technologies" />
              </Link>
            </div>
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">Forgot Password?</h3>
                <p className="account-subtitle">
                  Enter your email to get a password reset link
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="input-block">
                    <label>Email Address</label>
                    <input
                      className="form-control"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                    {error && <span className="text-danger">{error}</span>}
                  </div>
                  <div className="input-block text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? "Sending..." : "Reset Password"}
                    </button>
                  </div>
                  <div className="account-footer">
                    <p>
                      Remember your password? <Link to="/">Login</Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
