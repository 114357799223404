import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'


export const fetchRoles = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`roles`)
    return data.data;
}

export const addRole = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`roles`, payload)
    return data?.data;
}
export const updateRole = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`roles/${res?.id}`,res)
    return data?.data;
}
export const deleteRole = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`roles/${id}`);
    return data?.data;
}



// Permissions Mutations

export const fetchPermissionsAgainstRoles = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`/modules/permissions/${id}`)
    return data;
}