import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { useMutation } from "@tanstack/react-query";
import { verifyOtp } from "../../../hooks/useAuth";
import InfoToast from "../../layout/InfoToast";

const Otp = () => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [toastType, setToastType] = useState("")
  const [success, setSuccess] = useState(false);
  const inputRefs = useRef([]);
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email; // Retrieve email from location state

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };
  const { mutate: verifyOtpMutation } = useMutation({
    mutationFn: verifyOtp,
    onSuccess: () => {
      navigate("/reset-password", { state: { email } });
    },
    onError: (error) => {
      setSuccess(true);
      setToastType({
        label: error?.response?.data?.data || error?.message || "Couldn't Verify OTP",
        type: "Error",
      });
    },
  });

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^[0-9]?$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      if (value !== "" && index < 5) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && otp[index] === "" && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    const paste = e.clipboardData.getData('text');
    if (/^\d{6}$/.test(paste)) {
      const newOtp = paste.split("");
      setOtp(newOtp);

      // Focus the last input field
      inputRefs.current[5].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    verifyOtpMutation({ email, otp: otp.join("") });
  };

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            <div className="account-logo">
              <Link to="/admin-dashboard">
                <img src={Applogo} alt="Xpert Prime" />
              </Link>
            </div>
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">OTP</h3>
                <p className="account-subtitle">Verify your account</p>
                <form onSubmit={handleSubmit}>
                  <div className="otp-wrap" onPaste={handlePaste}>
                    {otp.map((value, index) => (
                      <input
                        key={index}
                        type="text"
                        maxLength={1}
                        className="otp-input"
                        value={value}
                        onChange={(e) => handleChange(e, index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        ref={(el) => (inputRefs.current[index] = el)}
                      />
                    ))}
                  </div>
                  <div className="input-block mb-4 text-center">
                    <button
                      className="btn btn-primary account-btn"
                      type="submit"
                    >
                      Enter
                    </button>
                  </div>
                  <div className="account-footer">
                    <p>
                      Not yet received? <Link to={`/forgot-password?email=${email}`}>Resend OTP</Link>
                    </p>
                  </div>
                </form>

                <InfoToast
                  success={success}
                  handleClose={handleSolidPrimaryToastClose}
                  toastType={toastType}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
