import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { addBankInformation } from "../../hooks/useEmployeeMoreInfo";
import { useLocation } from "react-router-dom";

const schema = yup.object().shape({
  bankName: yup.string().required("Bank Name is required"),
  accountNo: yup.string().required("Bank Account No. is required"),
  ifscCode: yup.string().required("Account Title is required"),
});

const PersonalInfoBankInfo = ({ employee_bank = [], refetch }) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");


  // Initialize the form
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      bankName: employee_bank[0]?.bank_name || "",
      accountNo: employee_bank[0]?.account_no || "",
      ifscCode: employee_bank[0]?.title || "",
    },
  });

  // Update form values when employee_bank changes
  useEffect(() => {
    if (employee_bank.length > 0) {
      reset({
        bankName: employee_bank[0]?.bank_name || "",
        accountNo: employee_bank[0]?.account_no || "",
        ifscCode: employee_bank[0]?.title || "",
      });
    }
  }, [employee_bank, reset]);

  const { mutate: addBankInformationMutation } = useMutation({
    mutationFn: addBankInformation,
    onSuccess: () => {
      reset();
      refetch();
    },
  });

  const onSubmit = (data) => {
    const payload = {
      employee_id: id,
      bank_name: data.bankName,
      account_no: data.accountNo,
      title: data.ifscCode,
    };
    addBankInformationMutation(payload);
  };

  return (
    <div
      id="bank-information-modal"
      className="modal custom-modal fade"
      role="dialog"
    >
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Bank Information</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="card">
                <div className="card-body">
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div
                        className={`input-block mb-3 form-focus focused ${
                          errors.bankName ? "has-error" : ""
                        }`}
                      >
                        <Controller
                          name="bankName"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type="text"
                                className="form-control floating"
                              />
                              <label className="focus-label">
                                Bank Name <span className="text-danger">*</span>
                              </label>
                              {errors.bankName && (
                                <p className="text-danger mt-1">
                                  {errors.bankName.message}
                                </p>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className={`input-block mb-3 form-focus focused ${
                          errors.accountNo ? "has-error" : ""
                        }`}
                      >
                        <Controller
                          name="accountNo"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type="text"
                                className="form-control floating"
                              />
                              <label className="focus-label">
                                Bank Account No.{" "}
                                <span className="text-danger">*</span>
                              </label>
                              {errors.accountNo && (
                                <p className="text-danger mt-1">
                                  {errors.accountNo.message}
                                </p>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <div
                        className={`input-block mb-3 form-focus focused ${
                          errors.ifscCode ? "has-error" : ""
                        }`}
                      >
                        <Controller
                          name="ifscCode"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type="text"
                                className="form-control floating"
                              />
                              <label className="focus-label">
                                Account Title{" "}
                                <span className="text-danger">*</span>
                              </label>
                              {errors.ifscCode && (
                                <p className="text-danger mt-1">
                                  {errors.ifscCode.message}
                                </p>
                              )}
                            </>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  type="submit"
                  disabled={!isValid}
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInfoBankInfo;
