import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'


export const fetchShift = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`shift`)
    return data.data;
}

export const addShift = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`shift`, payload)
    return data?.data;
}
// export const updateRole = async (res) => {
//     setAuthenticationToken(localStorage.getItem('token'))
//     const { data } = await axiosInstance.put(`roles/${res?.id}`,res)
//     return data?.data;
// }
// export const deleteRole = async (id) => {
//     setAuthenticationToken(localStorage.getItem('token'))
//     const { data } = await axiosInstance.delete(`roles/${id}`);
//     return data?.data;
// }