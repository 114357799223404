
import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'
export const addDesignations = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.post(`designations`, payload)   
   return data?.data;
}
export const fetchDesignations = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`designations`)
    return data.data;
}

export const deleteDesignations = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`designations?id=${id}`)
    return data?.data;
}
export const updateDesignations = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`designations?id=${res.id}&designation=${res.designation}&department=${res.department.value}`)
    return data?.data;
}