import { Table } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import EditUserModal from "../../../../components/Administration/Users/EditUseModal";
import { formatDate } from "../../../../assets/scss/utils/helpers/helper_functions";
import dayjs from "dayjs";

const UsersTable = ({ data, onSelectUserForDeletion, updateUser }) => {
  const [selectedId, setSelectedId] = useState(null);
  const [employeeCompany, setEmployeeCompany] = useState("XpertPrime");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };  

  const [selectedUserData, setSelectedUserData] = useState({});
  const handleDeleteClick = (id) => {
    onSelectUserForDeletion(id);
  };

  const formatDateTime = (date) => {
    return dayjs(date).format("DD-MM-YYYY h:mm A");
  };

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <div className="table-avatar">
          <img alt="" src={record?.avatar} />
          {text}{" "}
          <span>
            {record?.firstname} {record?.lastname || ''}
          </span>
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },

    {
      title: "Company",
      dataIndex: "employeeCompany",
      render: () => "Xpert Prime",
      // sorter: (a, b) => a.company.length - b.company.length,
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      render: (text) => formatDate(text),
      sorter: (a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA; // Ascending order
      },
      defaultSortOrder: 'ascend',
    },
    {
      title: "Last Activity",
      dataIndex: "last_activity",
      render: (text, record) => (record?.last_activity == null ? 'No Recent Activity' : formatDateTime(record?.last_activity)),
      
      sorter: (a, b) => new Date(a.last_activity) - new Date(b.last_activity),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (text) => (
        <span
          className={
            text === "Admin"
              ? "badge bg-inverse-danger"
              : "badge bg-inverse-success"
          }
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.role.length - b.role.length,
    },
    {
      title: "Action",
      render: (record) => (
        <div className="dropdown dropdown-action text-center">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_user"
              onClick={() => {
                setSelectedId(record.id);
                setSelectedUserData(record);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => handleDeleteClick(record.id)}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            pagination={pagination}
            onChange={handleTableChange}
          />
          <EditUserModal
            selectedId={selectedId}
            updateUser={updateUser}
            selectedUserData={selectedUserData}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
