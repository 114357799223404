import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import InfoToast from "../../../layout/InfoToast";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchAttendanceSettings,
  updateAttendanceSettings,
} from "../../../../hooks/useSettings";
import Spinnerloaders from "../../../../components/SpinnerLoader";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { addShift, fetchShift } from "../../../../hooks/useShift";
import AddShiftModal from "../../../../components/modelpopup/AddShiftModal";

const AttendanceSettings = () => {
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };


  // ==================================Get Shift data================================
  const { data: shiftData, error: shiftError, status: shiftStatus, refetch: shiftRefetch } = useQuery({
    queryKey: ["shifts"],
    queryFn: fetchShift,
    refetchOnWindowFocus: false,
  });

  console.log("shift data", shiftData);
  // Get Attendance Shift data
  const { data, refetch } = useQuery({
    queryKey: ["attendance-settings"],
    queryFn: fetchAttendanceSettings,
    refetchOnWindowFocus: false,
  });
// =============================Add Shift=====================================
  const { mutate: addShiftMutation } = useMutation({
    mutationFn: addShift,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
      setTimeout(() => {
        setSuccess(true);
        setToastType({ label: "Attendance Settings Updated", type: "Updated" });
      }, 300);
    },
    onError: (error) => {
      setTimeout(() => {
        setToastType({
          label: "An Error Occurred While Updating Attendance Settings",
          type: "Error",
        });
        setSuccess(true);
      }, 300);
    },
  });



  const { mutate: updateAttendanceSettingsPost } = useMutation({
    mutationFn: updateAttendanceSettings,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
      setTimeout(() => {
        setSuccess(true);
        setToastType({ label: "Attendance Settings Updated", type: "Updated" });
      }, 300);
    },
    onError: (error) => {
      setTimeout(() => {
        setToastType({
          label: "An Error Occurred While Updating Attendance Settings",
          type: "Error",
        });
        setSuccess(true);
      }, 300);
    },
  });

  const [isRefetching, setIsRefetching] = useState(false);
  const [formData, setFormData] = useState({
    flexibility: "",
  });
  console.log(formData, "====::::===:::----->");

  useEffect(() => {
    if (data) {
      setFormData({
        flexibility: data,
      });
    }
  }, [data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    updateAttendanceSettingsPost(formData);
  };

  const userElements = shiftData?.shifts?.map((shift, index) => ({
    key: index,
    id: shift.id,
    shiftName: shift.shift_name,
    shiftStart: shift.start_time,
    shiftEnd: shift.end_time,
  }));

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {((pagination.current - 1) * pagination.pageSize) + index + 1}
        </span>
      ),
    },
    {
      title: "Shift Name",
      dataIndex: "shiftName",
      sorter: (a, b) => a.shiftName.length - b.shiftName.length,
      width: "80%",
    },
    {
      title: "Shift Start Time",
      dataIndex: "shiftStart",
      sorter: (a, b) => a.shiftStart.length - b.shiftStart.length,
      width: "80%",
    },
    {
      title: "Shift End Time",
      dataIndex: "shiftEnd",
      sorter: (a, b) => a.shiftEnd.length - b.shiftEnd.length,
      width: "80%",
    },
    {
      title: "Action",
      className: "text-end",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_department"
              onClick={() => {

              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => {
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <Breadcrumbs maintitle="Attendance Settings" />
              <form onSubmit={handleFormSubmit}>
                <div className="input-block row px-4">
                  <label className="col-lg-2 col-form-label">Flexibility</label>
                  <div className="col-lg-8">
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <span className="input-group-text form-control bg-white rounded-0">Mins</span>
                      </div>
                      <input
                        name="flexibility"
                        className="form-control"
                        value={formData.flexibility}
                        onChange={handleInputChange}
                        type="number"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div className="">
                      <button className="btn btn-primary submit-btn" type="submit">
                        Save
                      </button>
                    </div>
                  </div>

                </div>

                {isRefetching && <Spinnerloaders />}

              </form>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-12">
              <div className="d-flex justify-content-end mb-3">
                <button
                  className="btn btn-primary submit-btn"
                  type="submit"
                  data-bs-toggle="modal"
                  data-bs-target="#addShiftModal"
                  onClick={() => {
                  }}
                >
                  Add Shift
                </button>
              </div>
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={userElements?.length > 0 ? userElements : []}
                  className="table-striped"
                  rowKey={(record) => record.id}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
                {isRefetching && <Spinnerloaders />}
              </div>
            </div>

          </div>
        </div>
      </div>
      <AddShiftModal  addShift={addShiftMutation}/>
      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </>
  );
};

export default AttendanceSettings;
